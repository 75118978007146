import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TabPanel from "../../../lms/component/tabPanel/tabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  ETRAININGTABS,
  STORMWATERETRAININGTABS,
} from "../../../../constant/constant";
import UserCoursesList from "../../../lms/component/userCoursesList/userCoursesList";
import UserLessonsList from "../../../lms/component/userLessonsList/userLessonsList";
import ToolBoxTalkUserList from "../../../lms/component/toolBoxTalkList/toolBoxTalkUserList";
import {
  checkPrivileges,
  showRighSideMenu,
} from "../../../../utils/rolesHelper";

const Etraining = ({
  tabtraingClick,
  coursesUserListData,
  getCoursesUserListData,
  isLoading,
  lessionUserListData,
  getLessionUserListData,
  isLessonLoading,
  isProfile,
  categoryListData,
  getToolBoxTalkListResult,
  getToolBoxTalkListData,
  usersId,
  isSafetyLog,
  // etrainingNewLogData,
  // defaultDataSafetymeeting
  showDownloadButton = false,
  safetyLogCheck,
}) => {
  const showMenu = showRighSideMenu();
  const [filterColumns, setFilterColumns] = useState([]);
  const checkSafetyMeetingPrivilege = checkPrivileges([12, 90, 91, 92]);

  function a11yProps(index, id) {
    return {
      id: `${id}`,
      "aria-controls": `${index}`,
    };
  }
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    tabtraingClick(event?.target?.id);
  };
  useEffect(() => {
    let updatedColumns = ETRAININGTABS;

    if (checkSafetyMeetingPrivilege) {
      if (parseInt(safetyLogCheck) === 0) {
        updatedColumns = ETRAININGTABS;
      } else if (parseInt(safetyLogCheck) === 1) {
        updatedColumns = ETRAININGTABS.filter(
          (store) => parseInt(store.id) === 3
        );
      }
    } else if (parseInt(safetyLogCheck) === 1) {
      updatedColumns = ETRAININGTABS.filter(
        (store) => parseInt(store.id) === 3
      );
    } else {
      updatedColumns = ETRAININGTABS.filter(
        (store) => parseInt(store.id) !== 3
      );
    }

    setFilterColumns(updatedColumns);
  }, [ETRAININGTABS, safetyLogCheck, checkSafetyMeetingPrivilege]);

  return (
    <Grid container>
      <Grid item md={12}>
        {showMenu?.isSafety && (
          <Tabs value={value} onChange={handleChange}>
            {filterColumns?.map((tabItem, i) => {
              return (
                <Tab
                  className="lessonList"
                  label={tabItem?.name}
                  key={i}
                  {...a11yProps(i, tabItem?.id)}
                />
              );
            })}
          </Tabs>
        )}
        {showMenu?.isSW && (
          <Tabs value={value} onChange={handleChange}>
            {STORMWATERETRAININGTABS?.map((tabItem, i) => {
              return (
                <Tab
                  className="lessonList"
                  label={tabItem?.name}
                  key={i}
                  {...a11yProps(i, tabItem?.id)}
                />
              );
            })}
          </Tabs>
        )}
      </Grid>
      <Grid item md={12}>
        {parseInt(safetyLogCheck) === 1 ? (
          <TabPanel value={value} index={0}>
            <ToolBoxTalkUserList
              categoryListData={categoryListData}
              getToolBoxTalkListResult={getToolBoxTalkListResult}
              getToolBoxTalkListData={getToolBoxTalkListData}
              isShow={false}
              isProfile={isProfile}
              isSafetyLog={isSafetyLog}
              usersId={usersId}
            />
          </TabPanel>
        ) : (
          <>
            <TabPanel value={value} index={0}>
              <UserCoursesList
                coursesUserListData={coursesUserListData}
                getCoursesUserListData={getCoursesUserListData}
                isLoading={isLoading}
                isShow={true}
                isProfile={isProfile}
                isSafetyLog={isSafetyLog}
                usersId={usersId}
                showDownloadButton={showDownloadButton}
                isAllTab={false}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <UserLessonsList
                lessionUserListData={lessionUserListData}
                getLessionUserListData={getLessionUserListData}
                isLoading={isLessonLoading}
                isShow={true}
                isProfile={isProfile}
                isSafetyLog={isSafetyLog}
                usersId={usersId}
                showDownloadButton={showDownloadButton}
                isAllTab={false}
              />
            </TabPanel>

            <TabPanel value={value} index={2}>
              <ToolBoxTalkUserList
                categoryListData={categoryListData}
                getToolBoxTalkListResult={getToolBoxTalkListResult}
                getToolBoxTalkListData={getToolBoxTalkListData}
                isShow={false}
                isProfile={isProfile}
                isSafetyLog={isSafetyLog}
                usersId={usersId}
              />
            </TabPanel>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Etraining;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as categoryAction from "../../../redux/actions/utilityCategoryAction";
import CategoryAdmin from "../../admin/component/utilityAdmin/categoryAdmin";
import { API_STATUS, ORG_ID } from "../../../constant/constant";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { ACCESS_DENIED } from "../../../constant/routeContant";
const CategoryAdminContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [catselectid, setCatselectid] = useState("");
  const createCategoryResult = useSelector(
    (state) => state.createCategory?.status
  );
  const activeCategoryResult = useSelector(
    (state) => state.activeCategory?.status
  );
  const UpdateCategoryResult = useSelector(
    (state) => state.updateCategoryConfigation?.status
  );
  const UpdateSupportCategoryResult = useSelector(
    (state) => state.updateSupportCategoryConfigation?.status
  );
  const UpdateIncidentCategoryResult = useSelector(
    (state) => state.updateIncidentCategoryConfigation?.status
  );
  const categriesAdminData = useSelector(
    (state) => state.categoryList?.data?.itemList
  );

  const categriesAdminIncidentData = useSelector(
    (state) => state.categoryUtilityIncidentList?.data
  );

  const IncisLoading = useSelector(
    (state) => state.categoryUtilityIncidentList?.isLoading
  );
  const categriesAdminIncidentBodyPart = useSelector(
    (state) => state.categoryUtilityIncidentList?.data?.bodyPart
  );

  const categriesIncidentInjuryType = categriesAdminIncidentData?.injuryType;
  const categriesIncidentTreatementType =
    categriesAdminIncidentData?.treatementType;

  let finalresult;
  if (catselectid === 1) {
    finalresult = categriesIncidentTreatementType
      ? categriesIncidentTreatementType
      : "";
  } else if (catselectid === 2) {
    finalresult = categriesAdminIncidentData?.contributingFactors
      ? categriesAdminIncidentData?.contributingFactors
      : "";
  } else if (catselectid === 3) {
    finalresult = categriesIncidentInjuryType
      ? categriesIncidentInjuryType
      : "";
  } else if (catselectid === 4) {
    finalresult = categriesAdminIncidentBodyPart
      ? categriesAdminIncidentBodyPart
      : "";
  } else if (catselectid === 5) {
    finalresult = categriesAdminIncidentData?.illnessType
      ? categriesAdminIncidentData?.illnessType
      : "";
  } else if (catselectid === 6) {
    finalresult = categriesAdminIncidentData?.caseClassify
      ? categriesAdminIncidentData?.caseClassify
      : "";
  } else if (catselectid === 7) {
    finalresult = categriesAdminIncidentData?.contributingFactorVehicle
      ? categriesAdminIncidentData?.contributingFactorVehicle
      : "";
  } else if (catselectid === 8) {
    finalresult = categriesAdminIncidentData?.trafficAtTimeOfIncident
      ? categriesAdminIncidentData?.trafficAtTimeOfIncident
      : "";
  } else if (catselectid === 9) {
    finalresult = categriesAdminIncidentData?.utilityPropertyDamaged
      ? categriesAdminIncidentData?.utilityPropertyDamaged
      : "";
  } else if (catselectid === 10) {
    finalresult = categriesAdminIncidentData?.typeOfUtility
      ? categriesAdminIncidentData?.typeOfUtility
      : "";
  } else if (catselectid === 11) {
    finalresult = categriesAdminIncidentData?.typeOfUtilityLineDamage
      ? categriesAdminIncidentData?.typeOfUtilityLineDamage
      : "";
  } else if (catselectid === 12) {
    finalresult = categriesAdminIncidentData?.wereUtilityLines
      ? categriesAdminIncidentData?.wereUtilityLines
      : "";
  } else if (catselectid === 13) {
    finalresult = categriesAdminIncidentData?.locationOfRecord
      ? categriesAdminIncidentData?.locationOfRecord
      : "";
  }

  const isloader = useSelector((state) => state.categoryList?.isLoading);
  const isloadercat = useSelector((state) => state.activeCategory?.isLoading);
  const isloaderupdateconfi = useSelector(
    (state) => state.updateCategoryConfigation?.isLoading
  );
  const isloaderupdateSupport = useSelector(
    (state) => state.updateSupportCategoryConfigation?.isLoading
  );

  const isSupportloader = useSelector(
    (state) => state.categorySupportList?.isLoading
  );
  const isSupportloadercat = useSelector(
    (state) => state.activeSupportCategory?.isLoading
  );
  const SupportcategriesData = useSelector(
    (state) => state.categorySupportList?.data?.itemList
  );
  const createSupportCategoryResult = useSelector(
    (state) => state.createSupportCategory?.status
  );
  const activeSupportCategoryResult = useSelector(
    (state) => state.activeSupportCategory?.status
  );

  const createIncidentCategoryResult = useSelector(
    (state) => state.createUtilityIncidentCategory?.status
  );
  const activeIncidentCategoryResult = useSelector(
    (state) => state.activeIncidentCategory?.status
  );

  const location = useLocation();
  const { pathname } = location;
  const categoryPath = pathname.split("/")[3] ?? null;
  const [flag, setFlag] = useState("");

  useEffect(() => {
    if (
      createCategoryResult === API_STATUS.SUCCESS ||
      activeCategoryResult === API_STATUS.SUCCESS ||
      UpdateCategoryResult === API_STATUS.SUCCESS
    ) {
      const getDropdownDataKeyValueData = {
        orgId: ORG_ID(),
        flags: flag,
      };
      dispatch(
        categoryAction.getUtilityCategoryValue(getDropdownDataKeyValueData)
      );
    } else {
      //setAssignmsg(false);
    }
  }, [createCategoryResult, activeCategoryResult, UpdateCategoryResult]);

  useEffect(() => {
    if (
      createSupportCategoryResult === API_STATUS.SUCCESS ||
      activeSupportCategoryResult === API_STATUS.SUCCESS ||
      UpdateSupportCategoryResult === API_STATUS.SUCCESS
    ) {
      const getDropdownDataKeyValueData = {
        orgId: ORG_ID(),
        flags: flag,
      };
      dispatch(
        categoryAction.getUtilitySupportCategoryValue(
          getDropdownDataKeyValueData
        )
      );
    } else {
      //setAssignmsg(false);
    }
  }, [
    createSupportCategoryResult,
    activeSupportCategoryResult,
    UpdateSupportCategoryResult,
  ]);

  useEffect(() => {
    if (
      createIncidentCategoryResult === API_STATUS.SUCCESS ||
      activeIncidentCategoryResult === API_STATUS.SUCCESS ||
      UpdateIncidentCategoryResult === API_STATUS.SUCCESS
    ) {
      const getDropdownDataKeyValueData = {
        orgId: ORG_ID(),
        flags: flag,
        isAll: 1,
      };
      dispatch(
        categoryAction.getUtilityIncidentCategoryValue(
          getDropdownDataKeyValueData
        )
      );
    } else {
      //setAssignmsg(false);
    }
  }, [
    createIncidentCategoryResult,
    activeIncidentCategoryResult,
    UpdateIncidentCategoryResult,
  ]);

  // getcategorylist Api onchange method  for LMS , Resourse,admin,assets,credentials category
  const getUtilityCategory = (e) => {
    setCatselectid(e.target.value);
    setFlag(e.target.value);
    const getDropdownDataKeyValueData = {
      orgId: ORG_ID(),
      flags: [e.target.value],
    };
    dispatch(
      categoryAction.getUtilityCategoryValue(getDropdownDataKeyValueData)
    );
  };

  const getSupportUtilityCategroy = (e) => {
    if (e.target.value === 1) {
      setCatselectid(e.target.value);
      setFlag(e.target.value);

      const getSupportCategoryData = {
        orgId: ORG_ID(),
        flags: [e.target.value],
      };
      dispatch(
        categoryAction.getUtilitySupportCategoryValue(getSupportCategoryData)
      );
    } else if (e.target.value === 13) {
      setCatselectid(e.target.value);
      setFlag(e.target.value);
      const getDropdownDataKeyValueData = {
        orgId: ORG_ID(),
        flags: [e.target.value],
      };
      dispatch(
        categoryAction.getUtilityCategoryValue(getDropdownDataKeyValueData)
      );
    }
  };

  // Incident Api /GetReferenceData Api onchange method  for Incident category

  const getUtilityIncidentCategory = (e) => {
    setCatselectid(e.target.value);
    setFlag(e.target.value);
    const UtilitycatData = {
      isAll: 1,
    };
    if (e.target.value !== 13) {
      dispatch(categoryAction.getUtilityIncidentCategoryValue(UtilitycatData));
    }
  };

  const onSubmit = (data) => {
    if (categoryPath === "support" && catselectid === 1) {
      dispatch(categoryAction.utilitySupportCategory(data));
      setTimeout(() => {
        toast("Category Added successfully");
      }, 1000);
    } else if (categoryPath === "incident") {
      dispatch(categoryAction.utilityIncidentCategory(data));
      setTimeout(() => {
        toast("Category Added successfully");
      }, 1000);
    } else {
      dispatch(categoryAction.utilityCategory(data));
      setTimeout(() => {
        toast("Category Added successfully");
      }, 1000);
    }
  };

  let titleCategory;
  if (categoryPath === "utility") {
    titleCategory = "Admin Category";
  } else if (categoryPath === "lms") {
    titleCategory = "Learning Category";
  } else if (categoryPath === "support") {
    titleCategory = "Support Category";
  } else if (categoryPath === "assets") {
    titleCategory = "Assets Category";
  } else if (categoryPath === "incident") {
    titleCategory = "Incident Category";
  } else if (categoryPath === "credentials") {
    titleCategory = "Credentials Category";
  } else if (categoryPath === "resourse") {
    titleCategory = "Resourse Category";
  }
  
  let access = false;
  useEffect(() => {
    if (categoryPath === "incident") {
      access = checkPrivileges([12, 1, 2, 151, 152, 153]);
    } else if (categoryPath === "lms") {
      access = checkPrivileges([12, 11, 90]);
    } else if (categoryPath === "resourse") {
      access = checkPrivileges([12, 73, 74]);
    } else if (categoryPath === "support") {
      access = checkPrivileges([12, 7]);
    } else if (categoryPath === "utility") {
      access = checkPrivileges([12, 64, 43, 44, 78, 52]);
    } else if (categoryPath === "credentials") {
      access = checkPrivileges([12, 13]);
    } else if (categoryPath === "assets") {
      access = checkPrivileges([12, 37]);
    }
  }, []);
  useEffect(() => {
    if (!access) navigate(ACCESS_DENIED);
  }, [access]);

  useEffect(() => {
    if (location?.state?.flag || location?.state?.routeProps) {
      setFlag(location?.state?.flag || location?.state?.routeProps?.flag);
    }
  }, [location?.state?.flag]);

  return (
    <>
      {isloader && <Loader />}
      {isloadercat && <Loader />}
      {isloaderupdateconfi && <Loader />}
      {isloaderupdateSupport && <Loader />}
      {isSupportloader && <Loader />}
      {isSupportloadercat || (IncisLoading && <Loader />)}

      <CategoryAdmin
        data={
          categoryPath === "support" && catselectid === 1
            ? SupportcategriesData
              ? SupportcategriesData
              : ""
            : categoryPath === "resourse" && catselectid === 3
            ? categriesAdminData
              ? categriesAdminData
              : ""
            : categoryPath === "support" && catselectid === 13
            ? categriesAdminData
              ? categriesAdminData
              : ""
            : categoryPath === "incident"
            ? finalresult
              ? finalresult
              : ""
            : categriesAdminData
            ? categriesAdminData
            : ""
        }
        title={titleCategory}
        getUtilityCategory={getUtilityCategory}
        getSupportUtilityCategroy={getSupportUtilityCategroy}
        getUtilityIncidentCategory={getUtilityIncidentCategory}
        catselectid={catselectid}
        categoryPath={categoryPath}
        onSubmit={onSubmit}
        flag={flag}
      />
      <ToastContainer />
    </>
  );
};

export default CategoryAdminContainer;

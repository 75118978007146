import React, { useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import ViewcCasetable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import { useNavigate } from "react-router-dom";
import UserFilterList from "../../shared/UserFilterList ";
import { TableLoader } from "../../../../library/common/Loader";
import { PAGE_SIZE, ROW_PER_PAGE_SIZE } from "../../../../constant/constant";
import {
  ADMIN_CONTRACTOR,
  ADMIN_CONTRACTOR_DETAILS,
  ADMIN_SAFETY_LOG,
} from "../../../../constant/routeContant";
import DialogBox from "../../../../library/common/DialogBox";
import NORow from "../../../../library/custom/noRow/noRow";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import downloadIcon from "../../../../assets/downloadIcon.svg";

function ContractorTable({
  contractorGrades,
  deleteList,
  loading,
  getContractorListData,
  contractorListData,
  loadingContractor,
  filterShow = true,
  contractorTypes,
  isShowDowdownloadButton = true,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");
  const [searchlocation, setSearchlocation] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const navigate = useNavigate();
  const [company, setCompany] = useState("");
  const [type, setType] = useState("");
  const [isTableLoading, setisTableLoading] = useState(false);
  const deletehandle = () => {
    setOpenModal(false);
    deleteList(selectedRow.id);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      page: 1,
      pageSize: pageSize,
      search: searchText ? searchText : "",
      gradeId: company,
      divisionId: 0,
      type: type,
      isSendMail: false,
    };

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getContractorListData(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      page: page,
      pageSize: newPazeSize,
      search: search,
      gradeId: company,
      divisionId: 0,
      type: type,
      isSendMail: false,
    };

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getContractorListData(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      pageSize: pageSize,
      search: search,
      gradeId: company,
      divisionId: 0,
      type: type,
      isSendMail: false,
    };
    getContractorListData(data);
  };

  const handleViewOnClick = (rows) => {
    navigate(ADMIN_CONTRACTOR_DETAILS, {
      state: { rowsss: rows.row, editMode: false },
    });
  };

  const handleOnclick = (rows) => {
    navigate(ADMIN_CONTRACTOR, {
      state: { contractorId: rows.row.id, editMode: true },
    });
  };
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const deleteIncidents = (rows) => {
    setOpenModal(true);
    setSelectedRow(rows);
  };

  const safetyLogOnClick = (rows) => {
    navigate(ADMIN_SAFETY_LOG, {
      state: { userId: rows.row, userSafetyLog: true },
    });
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "contractorName",
      headerName: "Sub-Contractor Name",
      flex: 1.8,
    },
    // { field: "pocFirstName", headerName: "First Name", flex: 1.5 },
    // { field: "pocLastName", headerName: "Last Name", flex: 1.5 },
    {
      field: "pointOfContactName",
      headerName: "Point of Contact Name",
      type: "text",
      flex: 1.5,
      minWidth: 90,
      // renderCell: (cellValues) => {
      //   return (
      //     <>
      //       {cellValues?.row?.pocFirstName + " " + cellValues?.row?.pocLastName}
      //     </>
      //   );
      // },
    },

    { field: "contractorType", headerName: "Type", flex: 1.9 },
    { field: "grade", headerName: "Grade", flex: 1 },

    {
      field: "icon",
      renderHeader: () =>
        filterShow ? (
          <SettingTable
            noOfRecords={contractorListData?.noOfRecords ?? 0}
            numberOfItemsPerPage={PAGE_SIZE}
            incidentLists={contractorListData?.result?.contractorData ?? []}
            fileNameXL={"Contractor List"}
          />
        ) : (
          ""
        ),
      width: 68,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return filterShow ? (
          <ReportRowManipulte
            rows={rows}
            viewOnClick={() => handleViewOnClick(rows)}
            editOnClick={() => handleOnclick(rows)}
            viewLabel="View Details"
            deleteOnClick={() => deleteIncidents(rows?.row)}
            edit={checkPrivileges([12, 43, 44])}
            editLabel="Edit Contractor"
            download={false}
            view={checkPrivileges([12, 43, 44, 45])}
            deleted={checkPrivileges([12, 43])}
            deleteLabel="Delete Contractor"
            safetyLogLabel="Safety Log"
            safetyLogOnClick={() => safetyLogOnClick(rows)}
            safetyLog={checkPrivileges([12, 43, 44, 45])}
          />
        ) : (
          ""
        );
      },
    },
  ];

  const companyOnChanges = (e) => {
    setCompany(e.target.value);
  };

  const handleSearch = () => {
    setAnchorEl(null);
    window.close();
    const data = {
      page: 1,
      pageSize: pageSize,
      search: search,
      gradeId: company,
      divisionId: 0,
      type: type,
      isSendMail: false,
    };

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getContractorListData(data);
  };
  const handleClear = () => {
    setCompany("");
    setType("");
    setAnchorEl(null);
    window.close();
    setSearch("");
    const defaultData = {
      page: 1,
      pageSize: pageSize,
      search: "",
      gradeId: "",
      divisionId: 0,
      type: "",
      isSendMail: false,
    };

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getContractorListData(defaultData);
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      navigate(ADMIN_CONTRACTOR_DETAILS, {
        state: { rowsss: rows.row, editMode: false },
      });
    }
  };

  const sendEmail = () => {
    const data = {
      page: page,
      pageSize: pageSize,
      search: search,
      gradeId: company,
      divisionId: 0,
      type: type,
      isSendMail: true,
    };
    getContractorListData(data);
  };

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          {openModal && (
            <DialogBox
              open={openModal}
              rows={[]}
              deletehandle={deletehandle}
              handleCloseModal={() => setOpenModal(false)}
              view
              content={"Are you sure you want to delete this Contractor?"}
            />
          )}
        </div>
        <div className="grid_main_body">
          <div className="div-filter-container">
            <div className="filter_of_caselist">
              <div className="searchcaseid">
                <label htmlFor="search">
                  <span>
                    <SearchIcon />
                  </span>
                  <div>
                    <input
                      id="search"
                      placeholder="Search"
                      type="text"
                      value={search}
                      className=""
                      onChange={handleautoSearch}
                      autoComplete="off"
                    />
                  </div>
                </label>
              </div>
              {filterShow && (
                <div className="filter_btn">
                  <button
                    aria-describedby={id}
                    variant="contained"
                    type="button"
                    name="btn"
                    onClick={(e) => handleClick(e)}
                  >
                    <FilterListIcon id="icn" /> Filters
                  </button>
                  {/* <FilterButton
                label={"Filters"}
                handleClick={(e) => handleClick(e)}
              /> */}
                  <UserFilterList
                    firstLabel="Grade"
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    searchlocation={searchlocation}
                    setSearchlocation={setSearchlocation}
                    handleApply={handleSearch}
                    firstLebelFilter={contractorGrades}
                    firstLebelOnChanges={companyOnChanges}
                    firstLebelValue={company}
                    secondLebelFilter={contractorTypes}
                    secondLebelOnChanges={(e) => setType(e.target.value)}
                    secondLabel={"Type"}
                    secondLebelValue={type}
                    handleClear={handleClear}
                    resetBtn={true}
                    isShow={true}
                  />
                </div>
              )}
            </div>
            {isShowDowdownloadButton && (
              <div className="div-download-email-right" onClick={sendEmail}>
                <span>
                  <img
                    src={downloadIcon}
                    className="DownLoadLogo"
                    alt="DownLoad Logo"
                  />
                </span>
                Download Data
              </div>
            )}
          </div>
          {isTableLoading ? (
            ""
          ) : (
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <ViewcCasetable
                  getRowId={(r) => r.id}
                  rows={contractorListData?.result?.contractorData || []}
                  rowCount={contractorListData?.result?.noOfRecords || 0}
                  columns={columns}
                  onClick={(rows) => onRowClick(rows)}
                  loading={loading || loadingContractor}
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                  checkboxSelection={false}
                  page={page}
                  pagination
                  pageSize={pageSize}
                  rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                  onPageSizeChange={handlePageSizeChange}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default ContractorTable;

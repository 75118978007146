import React, { useState } from "react";
import "../../../incidents/cases/addCaseList.scss";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import Buttons from "../../../../library/custom/button/Button";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import { PAGE_SIZE, ROW_PER_PAGE_SIZE } from "../../../../constant/constant";
import { ADD_ADMIN_GROUP } from "../../../../../src/constant/routeContant";
import DialogBox from "../../../../library/common/DialogBox";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import UserFilterList from "../../shared/UserFilterList ";
import FilterListIcon from "@mui/icons-material/FilterList";
import downloadIcon from "../../../../assets/downloadIcon.svg";

export default function UserGroupList({
  GroupListData,
  noOfRecords,
  getuserGroupLists,
  loading,
  deleteGroup,
  isDivision = true,
  divisiondata,
  isShowDowdownloadButton = true,
}) {
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [divisionfilter, setDivisionFilter] = useState("");
  const [userGroupList, setUserGroupList] = useState({
    page: 1,
    pageSize: pageSize,
    search: "",
    divisionId: divisionfilter ? divisionfilter : 0,
    allGroups: divisionfilter === "" || divisionfilter === 0,
    isSendMail: false,
  });
  const [isTableLoading, setisTableLoading] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const divisionFilterOnChanges = (e) => {
    setDivisionFilter(e.target.value);
  };
  const deletehandle = () => {
    setOpenModal(false);
    deleteGroup(selectedRow.groupId);
  };

  const handleOnclick = (rows) => {
    navigate(ADD_ADMIN_GROUP, {
      state: { groupId: rows.row.groupId, editMode: true },
    });
  };

  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const deleteIncidents = (rows) => {
    setOpenModal(true);
    setSelectedRow(rows);
  };

  const navigate = useNavigate();
  const handleClickAdd = () => {
    navigate(ADD_ADMIN_GROUP);
  };

  const columns = [
    { field: "groupId", headerName: "Group ID", flex: 1.5 },
    { field: "value", headerName: "Group Name", flex: 2.5 },
    { field: "startDate", headerName: "Start Date", flex: 2 },
    { field: "userCount", headerName: "Users", flex: 1 },
    {
      field: "divisionName",
      headerName: "Division",
      flex: 1,
      renderCell: (rows) => {
        return (
          <>
            {rows?.row?.divisionName ? rows.row.divisionName : "All Divisions"}
          </>
        );
      },
    },
    isDivision && {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={noOfRecords ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={GroupListData ?? []}
          fileNameXL="list-of-group.csv"
        />
      ),
      width: 68,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            editOnClick={() => handleOnclick(rows)}
            deleteOnClick={() => deleteIncidents(rows?.row)}
            edit={checkPrivileges([55, 56, 12])}
            editLabel="Edit Group"
            download={false}
            viewLabel="View User"
            view={false}
            deleted={checkPrivileges([55, 12])}
            deleteLabel="Delete Group"
          />
        );
      },
    },
  ];

  const handleautoSearch = (event) => {
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setPage(1);
    let searchitm = "";
    if (event.target.value) {
      searchitm = event.target.value;
    }
    setSearch(searchitm);
    const data = {
      ...userGroupList,
      search: searchitm ? searchitm : "",
      isSendMail: false,
    };
    setUserGroupList(data);
    getuserGroupLists(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getuserGroupLists({
      ...userGroupList,
      page: newPage + 1,
      pageSize: pageSize,
      divisionId: divisionfilter ? divisionfilter : 0,
      allGroups: divisionfilter === "" || divisionfilter === 0,
      isSendMail: false,
    });
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      page: 1,
      pageSize: newPazeSize,
      search: search ? search : "",
      divisionId: divisionfilter ? divisionfilter : 0,
      allGroups: divisionfilter === "" || divisionfilter === 0,
      isSendMail: false,
    };
    getuserGroupLists(data);
  };

  const handleClear = () => {
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setPage(1);
    setAnchorEl(null);
    setDivisionFilter("");
    setSearch("");
    window.close();
    const data = {
      page: 1,
      pageSize: pageSize,
      search: "",
      divisionId: 0,
      allGroups: true,
      isSendMail: false,
    };
    getuserGroupLists(data);
  };
  const handleApply = () => {
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setPage(1);
    setAnchorEl(null);
    window.close();
    const data = {
      page: 1,
      pageSize: pageSize,
      search: search ? search : "",
      divisionId: divisionfilter ? divisionfilter : 0,
      allGroups: divisionfilter === "" || divisionfilter === 0,
      isSendMail: false,
    };
    getuserGroupLists(data);
  };

  const sendEmail = () => {
    const data = {
      page: 1,
      pageSize: pageSize,
      search: search ? search : "",
      divisionId: divisionfilter ? divisionfilter : 0,
      allGroups: divisionfilter === "" || divisionfilter === 0,
      isSendMail: true,
    };
    getuserGroupLists(data);
  };

  return (
    <>
      <section className="grid_main_sec">
        {isDivision && (
          <div className="grid_main_header two_grid">
            <div className="grid_heading_style">Users Groups</div>
            {openModal && (
              <DialogBox
                open={openModal}
                rows={[]}
                header={"Confirm Deletion"}
                deletehandle={deletehandle}
                handleCloseModal={() => setOpenModal(false)}
                content={"Are you sure you want to delete this Group?"}
                view
              />
            )}

            <div>
              <div className="filter_btn">
                {checkPrivileges([12, 55, 56, 57]) && (
                  <Buttons
                    id="btnAddGroup"
                    label={"Add Group"}
                    varientAddIconBlue={true}
                    onClick={(e) => handleClickAdd()}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <div className="grid_main_body">
          <div className="div-filter-container">
            <div className="filter_of_caselist">
              <div className="searchcaseid">
                <label htmlFor="search">
                  <span>
                    <SearchIcon />
                  </span>
                  <div>
                    <input
                      id="search"
                      placeholder="Search"
                      type="text"
                      value={search}
                      className=""
                      onChange={handleautoSearch}
                      autoComplete="off"
                    />
                  </div>
                </label>
              </div>
              {isDivision && (
                <div className="filter_btn">
                  <button
                    aria-describedby={id}
                    variant="contained"
                    type="button"
                    name="btn"
                    onClick={(e) => handleClick(e)}
                  >
                    <FilterListIcon id="icn" /> Filters
                  </button>
                  <UserFilterList
                    firstLabel="Division"
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    handleApply={handleApply}
                    handleClear={handleClear}
                    firstLebelFilter={divisiondata}
                    resetBtn={true}
                    isShow={true}
                    firstLebelOnChanges={divisionFilterOnChanges}
                    firstLebelValue={divisionfilter}
                  />
                </div>
              )}
            </div>
            {isShowDowdownloadButton && (
              <div className="div-download-email-right" onClick={sendEmail}>
                <span>
                  <img
                    src={downloadIcon}
                    className="DownLoadLogo"
                    alt="DownLoad Logo"
                  />
                </span>
                Download Data
              </div>
            )}
          </div>
          {isTableLoading ? (
            ""
          ) : (
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <GridTable
                  getRowId={(r) => r.groupId}
                  rows={GroupListData ?? []}
                  rowCount={noOfRecords ?? 0}
                  columns={columns}
                  loading={loading}
                  checkboxSelection={false}
                  page={page}
                  pagination
                  pageSize={pageSize}
                  rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                  onPageSizeChange={handlePageSizeChange}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import AdminOpenTicket from "../component/ticket/adminOpenTicket";
import * as supportAction from "../../../redux/actions/supportAction";
import SuccessScreen from "../common/successSreen";
import * as userAction from "../../../redux/actions/userAction";
import { useState } from "react";
import Loader from "../../../library/common/Loader";
import { ACCESS_DENIED, ADMIN_TICKET } from "../../../constant/routeContant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { validateFileSize } from "../../../utils/helper";
import { fileUpload } from "../../../services/fileUploadService";

const AdminOpenTicketContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const queryString = window.location.search.slice(
    10,
    window.location.search?.length
  );
  // const ticketId = location?.state?.ticketId ?? queryString;
  const [ticketId, setticketId] = useState(location?.state?.ticketId ?? queryString)
  useEffect(() => {
    setticketId(location?.state?.ticketId ?? queryString)
  }, [])
  
  const getAdminDeskData = location?.state?.getAdminDeskData;
  const [status, setstatus] = useState(location?.state?.status);
  const ticketDetails = useSelector((state) => state?.getTicketDetails.data);
  const showLoader = useSelector(
    (state) =>
      state?.getTicketDetails?.isLoading ||
      state.getTicketCategory?.isLoading ||
      state.postClosedTicket.isLoading ||
      state.replyTicket.isLoading ||
      state.postUserAssignTicket.isLoading ||
      state.postUpdateSeverityTicket.isLoading
  );
  const [successScreens, setSuccessScreens] = useState(false);
  const postUserAssignStatus = useSelector(
    (state) => state.postUserAssignTicket.status
  );

  const replyTicketSatus = useSelector((state) => state.replyTicket.status);

  const closeTicketData = useSelector((state) => state?.postClosedTicket);
  console.log(closeTicketData);
  useEffect(() => {
    dispatch(supportAction.getTicketDetails(ticketId));
  }, [ticketId]);
  function handleTicketReply(replyData, ticketDetails) {
    dispatch(supportAction.replyTicket(replyData));
  }
  useEffect(() => {
    if (replyTicketSatus === "SUCCESS" || closeTicketData.status === "SUCCESS")
      dispatch(supportAction.getTicketDetails(ticketId));
  }, [replyTicketSatus, closeTicketData.status]);
  useEffect(() => {
    if (replyTicketSatus === "SUCCESS")
      dispatch(supportAction.getTicketDetails(ticketId));
  }, [replyTicketSatus]);

  function handleResolutionRequest(userAssign, severity) {
    dispatch(supportAction.postUpdateSeverityTicket(severity));
    dispatch(supportAction.postUserAssignTicket(userAssign));
    setSuccessScreens(true);
  }
  const assignSuccessScreen = (id) => {
    return (
      <SuccessScreen
        id={id}
        label1="Resolution Request Sent"
        label2=" "
        label3=" "
        label4=" "
        route={ADMIN_TICKET}
      ></SuccessScreen>
    );
  };

  const allUsers = useSelector((state) => state.allUsers);
  const isAutoSearchDataLoading = allUsers?.isLoading;
  function handleAllUserSearch(event, value) {
    dispatch(userAction.searchAllUsers(event.target.value, value));
  }

  const articleCatSubcategory = useSelector(
    (state) => state.getTicketCategory?.data?.ticketCategory
  );
  const [fileUploadData, setfileUploadData] = useState("");

  const [isLoader, setIsLoader] = useState(false);

  const uploadFile = async (data) => {
    const validation = validateFileSize(data);
    setIsLoader(true);
    fileUpload(data)
      .then((res) => {
        setIsLoader(false);
        const files = { files: res };
        setfileUploadData(files);
        console.log(files);
      })
      .catch((error) => {});
  };

  function handleTicketDetails(params) {
    dispatch(supportAction.getTicketDetails(params));
  }
  useEffect(() => {
    dispatch(supportAction.getTicketCategory(""));
  }, []);

  return (
    <div>
      {postUserAssignStatus === "SUCCESS" && successScreens ? (
        assignSuccessScreen("")
      ) : showLoader ? (
        <Loader />
      ) : (
        <AdminOpenTicket
          ticketDetails={ticketDetails?.ticketDetails}
          articleCatSubcategory={articleCatSubcategory}
          status={status}
          setstatus={setstatus}
          handleTicketReply={handleTicketReply}
          allUsers={allUsers?.data}
          isLoading={isAutoSearchDataLoading}
          handleAllUserSearch={handleAllUserSearch}
          getTicketApiData={getAdminDeskData}
          handleResolutionRequest={handleResolutionRequest}
          uploadFile={uploadFile}
          fileUploadData={fileUploadData}
          handleTicketDetails={handleTicketDetails}
          isLoader={isLoader}
        />
      )}
    </div>
  );
};

export default AdminOpenTicketContainer;

import React, { useEffect, useState } from "react";
import LmsHeader from "../component/lmsHeader/lmsHeader";
import {
  USERCOURSES,
  USERLESSON,
  DASHBOARD,
  LESSONDETAIL,
} from "../../../constant/routeContant";
import UserLessonsList from "../component/userLessonsList/userLessonsList";
import * as lmsAction from "../../../redux/actions/lmsAction";
import * as lmsUserAction from "../../../redux/actions/lmsUserAction";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS, ORG_ID, USER_ID } from "../../../constant/constant";
import { useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { useLocation } from "react-router-dom";
import Loader from "../../../library/common/Loader";

const UserLessonsContainer = () => {
  const dispatch = useDispatch();
  const PAGE_SIZE = 6;
  const lessionUserListData = useSelector((state) => state.getUserLessonsList);
  const location = useLocation();
  const islesson = location?.state?.islesson;

  const categoryUserListData = useSelector(
    (state) => state.getCategoryList?.data
  );

  const defaultCategoryData = {
    pageNumber: 1,
    isGlobal: "",
  };
  const getategoryListData = (data) => {
    dispatch(lmsAction.getCategoryList(data));
  };
  let navigate = useNavigate();
  const defaultData = {
    pageNumber: 1,
    pageSize: PAGE_SIZE,
    search: "",
    catIds: "",
    statusId: 2,
    userId: USER_ID(),
    orgId: ORG_ID(),
    isSendMail: false,
  };
  const [islessonId, setislessonId] = useState(null);
  const [isstart, setisstart] = useState(false);

  const startLessonData = useSelector((state) => state.startLesson);
  const showStartLoader = useSelector((state) => state.startLesson?.isLoading);
  const getLessionUserListData = (data) => {
    dispatch(lmsUserAction.getUserLessonsList(data));
  };

  useEffect(() => {
    const isAllowedDash = checkPrivileges([12, 11, 10, 9]);

    if (isAllowedDash === false) {
      navigate(DASHBOARD);
    }
    getLessionUserListData(defaultData);
    getategoryListData(defaultCategoryData);
  }, []);
  useEffect(() => {
    if (
      startLessonData.status === API_STATUS.SUCCESS &&
      (islesson || isstart)
    ) {
      navigate(LESSONDETAIL, {
        state: { lessonId: islessonId },
      });
    }
  }, [startLessonData, islesson]);

  const dataHeader = {
    title: "My Training",
    menu: [
      {
        route: USERCOURSES,
        text: "Courses",
      },
      {
        route: USERLESSON,
        text: "Lessons",
      },
    ],
  };
  const isLoading = useSelector((state) => state.getUserLessonsList?.isLoading);
  const postunEnrollLesson = (data) => {
    dispatch(lmsAction.unEnrollLesson(data));
  };
  return (
    <>
      {showStartLoader && isstart && <Loader />}

      <LmsHeader dataHeader={dataHeader} />
      <UserLessonsList
        lessionUserListData={lessionUserListData.data}
        getLessionUserListData={getLessionUserListData}
        categoryUserListData={categoryUserListData}
        isLoading={isLoading}
        isShow={true}
        islessonId={islessonId}
        setislessonId={setislessonId}
        setisstart={setisstart}
        postunEnrollLesson={postunEnrollLesson}
        showDownloadButton={false}
        isAllTab={true}
      />
    </>
  );
};

export default UserLessonsContainer;

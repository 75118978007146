import React, { useEffect, useState, useRef } from "react";
import {
  API_STATUS,
  ESTABLISHMENT_TYPE,
  OPEN_CASE_LIST_FILTER,
  PAGE_SIZE,
  USER_ID,
} from "../../../constant/constant";
import { TableLoader } from "../../../library/common/Loader";
import NORow from "../../../library/custom/noRow/noRow";
import GridTable from "../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import SettingTable from "../../incidents/incidentList/SettingTable";
import FilterCommonStatusSelect from "../../admin/shared/FilterCommonStatusSelect";
import TableRowAction from "../../../library/custom/gridTable/TableRowAction";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import { Grid, FormControl, Popover, Typography } from "@mui/material";
import "./oshaViewReport.scss";
import Button from "../../../library/custom/button/Button";
import commonImages from "../../../assets";
import { getEstablishmentTypeValue } from "../../../utils/helper";
import { checkPrivileges } from "../../../utils/rolesHelper";
import Na from "./na";
import { useThemeContext } from "../../../context";
import OshaRecordkeepingPdf from "./oshaRecordkeepingPdf";
import { useReactToPrint } from "react-to-print";
import DialogBox from "../../../library/common/DialogBox";
import { NumberField } from "../../../library/custom/numberField/numberField";
import OshaFormPdf from "./oshaFormPdf";
import Buttons from "../../../library/custom/button/Button";
import { toast } from "react-toastify";

const OshaRecordkeeping = (props) => {
  const [search, setSearch] = useState("");
  const [statusIds, setStatusIds] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOshaList, setIsOshaList] = useState(true);
  const [isEstablishmentDetailValid, setIsEstablishmentDetailValid] =
    useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { mode } = useThemeContext();
  const [isRecordale, setIsRecordale] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [numberOfEmployees, setNumberOfEmployees] = useState();
  const [hoursWorked, setHoursWorked] = useState();
  const [numberOfEmployeesError, setNumberOfEmployeesError] = useState(false);
  const [hoursWorkedError, setHoursWorkedError] = useState(false);
  const [numberOfEmployeesMessageError, setNumberOfEmployeesMessageError] =
    useState("");
  const [hoursWorkedMessageError, setHoursWorkedMessageError] = useState("");

  const handleEmpChange = (e) => {
    setNumberOfEmployees(e);
  };
  const handleHwChange = (e) => {
    setHoursWorked(e);
  };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "oshaRecordkeeping Detail",
    fonts: ["Lato"],
    bodyClass: "bodyPdf",
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [anchorElMessage, setAnchorElMessage] = React.useState(null);

  const handleClickMessage = (event) => {
    setAnchorElMessage(event.currentTarget);
  };

  const handleCloseMessage = () => {
    setAnchorElMessage(null);
  };

  const openMessage = Boolean(anchorElMessage);
  const idMessage = openMessage ? "simple-popover" : undefined;

  const [filter, setFilter] = useState({
    searchType: [],
  });

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      establishmentId: props?.establishmentId,
      year: props?.year,
      status: statusIds ? statusIds : "",
      search: searchText ? searchText : "",
      isIncidentRecordable:
        isRecordale === 1 ? 0 : isRecordale === 3 ? "" : isRecordale,
    };
    props?.getOshaLogData(data);
  };

  const columns = [
    { field: "incidentId", headerName: "Incident ID", flex: 1 },
    { field: "caseId", headerName: "Case ID", flex: 1 },
    { field: "dateOfEntry", headerName: "Date of Entry", flex: 1 },
    { field: "enteredBy", headerName: "Employee Name", flex: 1 },
    {
      field: "classification",
      headerName: "Classification",
      flex: 1,
    },
    {
      field: "illnessType",
      headerName: "Illness Type",
      flex: 1,
    },
    {
      field: "incidentStatus",
      headerName: "Item Status",
      flex: 1,
      renderCell: (rows) => {
        return (
          <>
            {rows?.row?.incidentStatus}{" "}
            {parseInt(rows?.row?.incidentStatusId) !== 1 ? (
              ""
            ) : (
              <Na text={false} />
            )}
          </>
        );
      },
    },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={props?.count ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={props?.logData ?? []}
          fileNameXL={"osha-reports"}
        />
      ),
      flex: 1,
      width: 170,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <TableRowAction
            rows={rows}
            view={checkPrivileges([12, 1, 2, 3])}
            edit={checkPrivileges([12, 1, 2])}
            viewLabel="View Report"
            editLabel="Edit Report"
            editCaseLabel="Edit Case"
            showMenu={checkPrivileges([12, 1, 2, 3]) ? true : false}
            viewOnClick={(row) => props?.handleViewOnClick(row)}
            editOnClick={(row) => props?.handleEditOnClick(row)}
            editCaseOnClick={(row) => props?.handleCaseEditOnClick(row)}
            editCase={true}
          />
        );
      },
    },
  ];

  const handleClear = () => {
    setSearch("");
    setStatusIds("");
    setIsRecordale("");
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      prevStates.searchType = [];
      return prevStates;
    });
    const dataClear = {
      establishmentId: props?.establishmentId,
      year: props?.year,
      status: "",
      search: "",
      isIncidentRecordable: "",
    };
    props.getOshaLogData(dataClear);
    handleClose();
  };
  const onChangeType = (e) => {
    setStatusIds(e.target.value);
  };
  const isRecordaleOnChanges = (e) => {
    setIsRecordale(e.target.value);
  };
  const handleApply = () => {
    const data = {
      establishmentId: props?.establishmentId,
      year: props?.year,
      status: statusIds ? statusIds : "",
      search: search ? search : "",
      isIncidentRecordable:
        isRecordale === 1 ? 0 : isRecordale === 3 ? "" : isRecordale,
    };
    props.getOshaLogData(data);
    handleClose();
  };

  const handleYearChange = (event) => {
    props?.setYear(event.target.value);
    setNumberOfEmployeesMessageError(false);
    setHoursWorkedMessageError(false);
    setNumberOfEmployeesError(false);
    setHoursWorkedError(false);
    props?.setIsSubmit(false);
  };
  const handleEstablishmentChange = (event) => {
    props?.setEstablishmentId(event.target.value);
    setNumberOfEmployeesMessageError(false);
    setHoursWorkedMessageError(false);
    setNumberOfEmployeesError(false);
    setHoursWorkedError(false);
    props?.setIsSubmit(false);
  };

  const getRowClassName = (params) => {
    if (
      params.row?.classification?.toLowerCase() === "death" ||
      params.row?.classification?.toLowerCase() === "other recordable"
    ) {
      if (
        params.row?.wasRecordableInOsha === 0 &&
        params.row?.classificationId &&
        params.row?.illnessTypeId &&
        params.row?.substanceDirectlyInjured &&
        parseInt(params.row?.incidentStatusId) !== 1
      ) {
        return "white";
      } else if (params.row?.wasRecordableInOsha === 0) {
        return "highlight-post-missedInspection";
      } else if (params.row?.wasRecordableInOsha === 2) {
        return "highlight-post-Draft";
      }
    } else if (params.row?.wasRecordableInOsha === 2) {
      return "highlight-post-Draft";
    } else if (
      params.row?.wasRecordableInOsha === 0 &&
      (params.row?.classifyHowManyDays === 0 ||
        params.row?.classifyHowManyDays === null ||
        !params.row?.classificationId ||
        !params.row?.illnessTypeId ||
        !params.row?.substanceDirectlyInjured)
    ) {
      return "highlight-post-missedInspection";
    } else if (
      params.row?.wasRecordableInOsha === 0 &&
      (params.row?.classifyHowManyDays !== null ||
        parseInt(params.row?.classifyHowManyDays) > 0) &&
      params.row?.classificationId &&
      params.row?.illnessTypeId &&
      params.row?.substanceDirectlyInjured
    ) {
      return "white";
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (props?.logData && props?.count !== 0 && props?.establishmentDetail && !statusIds) {
      const hasValidEntry = props?.logData?.every((entry) => {
        if (
          entry?.classification?.toLowerCase() === "death" ||
          entry?.classification?.toLowerCase() === "other recordable"
        ) {
          return (
            entry?.wasRecordableInOsha === 0 &&
            entry?.classificationId &&
            entry?.illnessTypeId &&
            entry?.substanceDirectlyInjured &&
            parseInt(entry?.incidentStatusId) !== 1
          );
        }
        return (
          entry?.wasRecordableInOsha === 0 &&
          (entry?.classifyHowManyDays === null ||
            entry?.classifyHowManyDays > 0) &&
          entry?.classificationId &&
          entry?.illnessTypeId &&
          entry?.substanceDirectlyInjured &&
          parseInt(entry?.incidentStatusId) !== 1
        );
      });
      setIsOshaList(!hasValidEntry);
    }
  }, [props?.logData, props?.count,statusIds]);

  useEffect(() => {
    if (props?.otherDetail) {
      setNumberOfEmployees(props?.otherDetail?.avgNumberOfEmployees ?? "");
      setHoursWorked(props?.otherDetail?.totalManHours ?? "");
    }
  }, [props?.otherDetail]);
  console.log('deploy update');

  useEffect(() => {
    if (props?.establishmentDetail) {
      const isEstablishmentDetailValid =
        props?.establishmentDetail?.establishmentId &&
        props?.establishmentDetail?.street &&
        props?.establishmentDetail?.city &&
        props?.establishmentDetail?.state &&
        props?.establishmentDetail?.zipCode &&
        props?.establishmentDetail?.naics &&
        props?.establishmentDetail?.ein
          ? false
          : true;
      setIsEstablishmentDetailValid(isEstablishmentDetailValid);
    }
  }, [props?.establishmentDetail, props?.year]);

  useEffect(() => {
    if (props?.count === 0) {
      setIsOshaList(true);
    }
  }, [props?.count]);

  useEffect(() => {
    if (
      !isOshaList &&
      !isEstablishmentDetailValid &&
      numberOfEmployees &&
      hoursWorked
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [isOshaList, isEstablishmentDetailValid, numberOfEmployees, hoursWorked]);

  const downloadhandle = () => {
    setOpenModal(true);
  };
  const downloadYeshandle = () => {
    setOpenModal(false);
    props?.setEmployeeName(false);
    props?.setPdfLoader(true);
    setTimeout(() => {
      handlePrint();
      props?.setPdfLoader(false);
    }, 1000);
  };
  const downloadNohandle = () => {
    setOpenModal(false);
    props?.setEmployeeName(true);
    props?.setPdfLoader(true);
    setTimeout(() => {
      handlePrint();
      props?.setPdfLoader(false);
    }, 1000);
  };
  const customClose = () => {
    props?.setEmployeeName(false);
    setOpenModal(false);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    let isValid = false;
    let numberOfEmployeesErrorMessage = "";
    let hoursWorkedErrorMessage = "";

    const numberOfEmployeesInt = parseInt(numberOfEmployees);
    if (isNaN(numberOfEmployeesInt)) {
      setNumberOfEmployeesError(true);
      numberOfEmployeesErrorMessage =
        "Annual Average Number of Employees must be a valid number.";
      isValid = true;
    } else if (numberOfEmployeesInt <= 0) {
      setNumberOfEmployeesError(true);
      numberOfEmployeesErrorMessage =
        "Annual Average Number of Employees must be greater than 0.";
      isValid = true;
    } else if (numberOfEmployeesInt >= 25000) {
      setNumberOfEmployeesError(true);
      numberOfEmployeesErrorMessage =
        "Annual Average Number of Employees must be less than 25,000.";
      isValid = true;
    }

    const hoursWorkedFloat = parseFloat(hoursWorked);
    if (isNaN(hoursWorkedFloat)) {
      setHoursWorkedError(true);
      hoursWorkedErrorMessage = "Total hours worked must be a valid number.";
      isValid = true;
    } else if (hoursWorkedFloat <= 0) {
      hoursWorkedErrorMessage = "Total hours worked must be greater than 0.";
      isValid = true;
    } else if (hoursWorkedFloat / numberOfEmployeesInt >= 8760) {
      hoursWorkedErrorMessage =
        "Total hours worked divided by Annual Average Employees must be less than 8,760.";
      isValid = true;
    } else if (hoursWorkedFloat / numberOfEmployeesInt <= 500) {
      hoursWorkedErrorMessage =
        "Total hours worked divided by Annual Average Employees must be greater than 500.";
      isValid = true;
    }
    if (!isValid) {
      const data = {
        establishmentId: props?.establishmentId,
        year: props?.year?.toString(),
        avgNumberOfEmployees: numberOfEmployeesInt,
        totalManHours: hoursWorkedFloat,
        userId: parseInt(USER_ID()),
      };

      props?.addOshaDetailHandler(data);
      setNumberOfEmployeesMessageError("");
      setHoursWorkedMessageError("");
      setNumberOfEmployeesError(false);
      setHoursWorkedError(false);
    } else {
      setNumberOfEmployeesMessageError(numberOfEmployeesErrorMessage);
      setHoursWorkedMessageError(hoursWorkedErrorMessage);
      setNumberOfEmployeesError(true);
      setHoursWorkedError(true);
      props?.setIsSubmit(false);
    }
  };

  const handleSubmitData = () => {
    if (parseInt(props?.year) === parseInt(props?.currentYear) || parseInt(props?.year) === parseInt(props?.currentYear - 2)) {
      toast("You can only submit OSHA Log for previous calendar year");
      return;
    } else {
      props?.setIsSubmit(true);
      handleSubmit();
    }    
  };

  useEffect(() => {
    if (
      props?.addGetOSHALog?.status === API_STATUS.SUCCESS &&
      props?.isSubmit
    ) {
      props?.handleSubmit();
    }
  }, [props?.addGetOSHALog, props?.isSubmit]);

  const component301Ref = useRef(null);
  const handle301Print = useReactToPrint({
    content: () => component301Ref.current,
    documentTitle: "OSHA Form 300 and related pages.",
    fonts: ["Lato"],
    bodyClass: "bodyPdf",
    onBeforeGetContent: () => {
      props?.setPdfLoader(true);
    },
    onAfterPrint: () => {
      props?.setEmployeeName(false);
      props?.setPdfLoader(false);
    },
  });

  return (
    <>
      {openModal && (
        <DialogBox
          open={openModal}
          rows={[]}
          fileIcon={true}
          header={"Employee's Name"}
          deletehandle={downloadYeshandle}
          handleCloseModal={customClose}
          customClose={downloadNohandle}
          customCloseShow={true}
          content={
            "Do you want to include the employee names in the OSHA Log download?"
          }
          view
        />
      )}

      <Grid container mb={3}>
        <Grid item container spacing={2} md={12} xs={12}>
          <Grid item lg={4} xs={12} sm={4} md={4}>
            <FormControl fullWidth>
              <FormLabels label="Year Submitting For" isRequired={true} />
              <SelectMenu
                placeholder="Please Select"
                listData={props?.years ?? []}
                value={props?.year}
                name="year"
                onchangehandler={handleYearChange}
              />
            </FormControl>
          </Grid>
          <Grid item lg={4} xs={12} sm={4} md={4}>
            <FormControl fullWidth>
              <FormLabels label="Establishment" isRequired={true} />
              <SelectMenu
                placeholder="Please Select"
                listData={props?.establishments}
                value={props?.establishmentId ?? ""}
                name="establishmentId"
                onchangehandler={handleEstablishmentChange}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            lg={4}
            xs={12}
            sm={4}
            md={4}
            mt={3}
            className="buttonSubmititaOsha"
          >
            {props?.establishmentId && (
              <>
                {" "}
                <Button
                  varientContained={true}
                  label={"Submit OSHA ITA"}
                  onClick={handleSubmitData}
                  disabled={buttonDisabled}
                />
                {buttonDisabled && (
                  <div className="disbledInfo">
                    <div
                      onClick={handleClickMessage}
                      className="disbledInfoImg"
                    >
                      <img src={commonImages?.help} alt={commonImages?.help} />
                    </div>

                    <Popover
                      id={idMessage}
                      open={openMessage}
                      anchorEl={anchorElMessage}
                      onClose={handleCloseMessage}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      className="disbledInfoContent"
                    >
                      <Typography sx={{ p: 2 }}>
                        Your incident forms are missing required information to
                        submit your OSHA ITA
                      </Typography>
                    </Popover>
                  </div>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      {props?.year && props?.establishmentId && (
        <>
          <Grid container className="overrideViewReports" mb={3}>
            <Grid item md={8} xs={12}>
              <div className="viewReportHeaderContainerBottom box-shadow mt-10">
                <div className="vrhctListBox">
                  <div className="vrhctList  w-100">
                    <div className="vrhctListItem">
                      Validate Establishment Details
                    </div>
                    {checkPrivileges([12, 152, 153]) && (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props?.editEstablishment(
                            props?.establishmentDetail?.establishmentId
                          )
                        }
                      >
                        <svg
                          width="14"
                          height="16"
                          viewBox="0 0 20 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 22V18H20V22H0ZM2 16V11.75L13.2 0.575C13.3833 0.391667 13.5958 0.25 13.8375 0.15C14.0792 0.05 14.3333 0 14.6 0C14.8667 0 15.125 0.05 15.375 0.15C15.625 0.25 15.85 0.4 16.05 0.6L17.425 2C17.625 2.18333 17.7708 2.4 17.8625 2.65C17.9542 2.9 18 3.15833 18 3.425C18 3.675 17.9542 3.92083 17.8625 4.1625C17.7708 4.40417 17.625 4.625 17.425 4.825L6.25 16H2ZM14.6 4.8L16 3.4L14.6 2L13.2 3.4L14.6 4.8Z"
                            fill={mode === "dark" ? "#004ddd" : "#1A446F"}
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
                <div className="vrhctListBox">
                  <div className="vrhctList  w-100">
                    <div className="vrhctListItem">
                      Establishment:
                      <span>
                        {props?.establishmentDetail?.establishmentName ? (
                          props?.establishmentDetail?.establishmentName
                        ) : (
                          <Na />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="vrhctListBox">
                  <div className="vrhctList">
                    <div className="vrhctListItem">
                      Street:
                      <span>
                        {props?.establishmentDetail?.street ? (
                          props?.establishmentDetail?.street
                        ) : (
                          <Na />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="vrhctList">
                    <div className="vrhctListItem">
                      City:{" "}
                      <span>
                        {props?.establishmentDetail?.city ? (
                          props?.establishmentDetail?.city
                        ) : (
                          <Na />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="vrhctListBox border-none">
                  <div className="vrhctList">
                    <div className="vrhctListItem">
                      State:
                      <span>
                        {props?.establishmentDetail?.state ? (
                          props?.establishmentDetail?.state
                        ) : (
                          <Na />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="vrhctList">
                    <div className="vrhctListItem">
                      Zip Code:
                      <span>
                        {props?.establishmentDetail?.zipCode ? (
                          props?.establishmentDetail?.zipCode
                        ) : (
                          <Na />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="vrhctListBox">
                  <div className="vrhctList  w-100">
                    <div className="vrhctListItem">
                      Industry Description:
                      <span>
                        {props?.establishmentDetail?.industryDescription}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="vrhctListBox">
                  <div className="vrhctList w-100">
                    <div className="vrhctListItem">
                      EIN:
                      <span>
                        {props?.establishmentDetail?.ein ? (
                          props?.establishmentDetail?.ein
                        ) : (
                          <Na />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="vrhctListBox">
                  <div className="vrhctList w-100">
                    <div className="vrhctListItem">
                      Establishment Type:
                      <span>
                        {getEstablishmentTypeValue(
                          props?.establishmentDetail?.establishmentType,
                          ESTABLISHMENT_TYPE
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="vrhctListBox">
                  <div className="vrhctList w-100">
                    <div className="vrhctListItem">
                      Standard Industrial Classification (SIC):
                      <span>{props?.establishmentDetail?.sic}</span>
                    </div>
                  </div>
                </div>
                <div className="vrhctListBox border-none">
                  <div className="vrhctList w-100">
                    <div className="vrhctListItem">
                      North American Industrial Classification (NAICS):
                      <span>
                        {props?.establishmentDetail?.naics ? (
                          props?.establishmentDetail?.naics
                        ) : (
                          <Na />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid container mb={3}>
              <Grid item container spacing={2} md={8} xs={12}>
                <Grid item lg={6} xs={12} sm={6} md={6}>
                  <NumberField
                    isRequired={true}
                    type="text"
                    label="Average Number of Employees"
                    name="numberofemployee"
                    placeholder={"Add Average Number of Employees Here..."}
                    value={numberOfEmployees}
                    onChange={handleEmpChange}
                  />
                  {numberOfEmployeesError && (
                    <p className="error">{numberOfEmployeesMessageError}</p>
                  )}
                </Grid>
                <Grid item lg={6} xs={12} sm={6} md={6}>
                  <NumberField
                    isRequired={true}
                    type="text"
                    label="Total Man Hours"
                    name="hoursworked"
                    placeholder={"Add Total Man Hours Here..."}
                    value={hoursWorked}
                    onChange={handleHwChange}
                  />
                  {hoursWorkedError && (
                    <p className="error">{hoursWorkedMessageError}</p>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={4} sm={4} xs={12} className="oshaa_button hideinpdf">
              <Button
                varientContained={true}
                label={"Download Osha Log"}
                onClick={downloadhandle}
              />
            </Grid>
            <Grid item md={4} sm={4} xs={12} className="oshaa_button">
              <Buttons
                varientContained={true}
                label={"Download 300A"}
                onClick={handle301Print}
                disabled={buttonDisabled}
              />
            </Grid>
          </Grid>

          <section className="grid_main_sec">
            <div className="grid_main_header two_grid">
              <div className="grid_heading_style">OSHA Log</div>
            </div>
            <div className="grid_main_body">
              <div className="filter_of_caselist">
                <div className="searchcaseid">
                  <label htmlFor="search">
                    <span>
                      <SearchIcon />
                    </span>
                    <div>
                      <input
                        id="search"
                        placeholder="Search"
                        type="text"
                        value={search}
                        className=""
                        onChange={handleautoSearch}
                        autoComplete="off"
                      />
                    </div>
                  </label>
                </div>

                <div className="filter_btn">
                  <button
                    aria-describedby={id}
                    variant="contained"
                    type="button"
                    name="btn"
                    onClick={(e) => handleClick(e)}
                  >
                    <FilterListIcon id="icn" /> Filters
                  </button>

                  <FilterCommonStatusSelect
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    selectLable={"Status"}
                    categoryType={OPEN_CASE_LIST_FILTER}
                    handleApply={handleApply}
                    handleClear={handleClear}
                    filter={filter}
                    categoryOnChanges={onChangeType}
                    category={statusIds}
                    filterSelect={true}
                    filterStatus={false}
                    resetBtn={true}
                    filterIsRecordaleSelect={true}
                    isRecordaleLable={"Is Incident Recordable?"}
                    isRecordale={isRecordale}
                    isRecordaleOnChanges={isRecordaleOnChanges}
                    spacing={{ pb: 2, pt: 0 }}
                  />
                </div>
              </div>

              <div className="desc_box row_uniq">
                <div className="table_style_ui">
                  <GridTable
                    getRowId={(r) => r.incidentId}
                    columns={columns}
                    rows={props?.logData ?? []}
                    rowCount={props?.count ?? 0}
                    loading={props?.isLoading}
                    components={{
                      LoadingOverlay: TableLoader,
                      NoRowsOverlay: NORow,
                    }}
                    pagination
                    hideFooterPagination={true}
                    RowClassName={getRowClassName}
                  />
                </div>
              </div>
            </div>
          </section>
          <Grid style={{ display: "none" }}>
            <div ref={component301Ref}>
              <OshaFormPdf
                oshaEstDetails={props?.establishmentDetail}
                oshaDetail={props?.getOshaDetails}
                hoursWorked={hoursWorked}
                numberOfEmployees={numberOfEmployees}
              />
            </div>
          </Grid>
          <div className="oshapdfshow">
            <div ref={componentRef}>
              <OshaRecordkeepingPdf
                headerInfo={props?.establishmentDetail}
                year={props?.year}
                oshaLog={props?.logData}
                employeeName={props?.employeeName}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OshaRecordkeeping;

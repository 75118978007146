import React, { useEffect, useState } from "react";
import CoursesList from "../component/coursesList/coursesList";
import LmsHeader from "../component/lmsHeader/lmsHeader";
import {
  CREATE_COURSE,
  COURSES,
  LESSON,
  DASHBOARD,
} from "../../../constant/routeContant";
import { useSelector, useDispatch } from "react-redux";
import * as lmsAction from "../../../redux/actions/lmsAction";
import {
  PAGE_SIZE,
  ORG_ID,
  USER_ID,
  API_STATUS,
} from "../../../constant/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const CoursesContainer = () => {
  const dispatch = useDispatch();
  const CoursedeleteStatus = useSelector((state) => state.deleteCourse.status);
  const [sendEmail, setSendEmail] = useState(false);
  const courseListData = useSelector((state) => state.getCoursesList);
  const publishCourseStatus = useSelector(
    (state) => state.publishCourse.status
  );
  const [isdisable, setisdisable] = useState(false);
  const publishCourses = async (data) => {
    setisdisable(true);
    dispatch(lmsAction.publishCourse(data));
  };
  let navigate = useNavigate();
  const dataHeader = {
    title: "Training Management",

    btnLink: CREATE_COURSE,
    menu: [
      {
        route: COURSES,
        text: "Courses",
      },
      {
        route: LESSON,
        text: "Lessons",
      },
    ],
  };
  if (checkPrivileges([12, 11, 10])) {
    dataHeader.btnText = "Create Course";
  }
  const defaultData = {
    pageNumber: 1,
    pageSize: PAGE_SIZE,
    search: "",
    catIds: "",
    userId: USER_ID(),
    organisationId: ORG_ID(),
    status: "",
    isSendMail: false,
  };
  const defaultCategoryData = {
    pageNumber: 1,
    isGlobal: "",
  };
  const categoryListData = useSelector((state) => state.getCategoryList?.data);

  useEffect(() => {
    const isAllowedDash = checkPrivileges([12, 11, 10]);

    if (isAllowedDash === false) {
      navigate(DASHBOARD);
    }
    getCoursesListData(defaultData);
    getategoryListData(defaultCategoryData);
  }, []);

  const getCoursesListData = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(lmsAction.getCoursesList(data));
  };
  const getategoryListData = (data) => {
    dispatch(lmsAction.getCategoryList(data));
  };
  const deleteCourse = (data) => {
    dispatch(lmsAction.deleteCourse(data));
  };

  useEffect(() => {
    if (courseListData?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("Courses has been sent to registered email ID");
    }
  }, [courseListData]);

  return (
    <>
      <LmsHeader dataHeader={dataHeader} />
      <ToastContainer />
      <CoursesList
        onSubmit={publishCourses}
        CoursedeleteStatus={CoursedeleteStatus}
        publishCourseStatus={publishCourseStatus}
        courseListData={courseListData}
        getCoursesListData={getCoursesListData}
        categoryListData={categoryListData}
        deleteCourse={deleteCourse}
        setisdisable={setisdisable}
        isdisable={isdisable}
      />
    </>
  );
};

export default CoursesContainer;

import React, { useState, useEffect } from "react";
import * as incpectionAction from "../../../../redux/actions/incpectionAction";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import { useDispatch, useSelector } from "react-redux";
import SuccessScreen from "../../../../library/custom/success/successScreen";
import Loader from "../../../../library/common/Loader";
import { INSPECTION_FORM_LIST } from "../../../../constant/routeContant";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import SetInspection from "../components/setInspection";
import * as stateCityAction from "../../../../redux/actions/stateCityAction";
import * as swCommonAction from "../../../../redux/storm-water/actions/swCommonAction";

const SetInspectionContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const questsetId = location?.state?.row;
  const editMode = location?.state?.editMode;
  const [successScreens, setSuccessScreens] = useState(false);
  const defaultCatData = {
    search: "",
    page: 1,
    pageSize: 1000,
  };
  const defaultSubCatData = {
    search: "",
    page: 1,
    pageSize: 1000,
  };
  const refData = {
    flags: 3,
  };
  const getSwReference = useSelector((state) => state.getSwReference);
  const getSwReferenceData = (data) => {
    dispatch(swCommonAction.getSwReference(data));
  };

  const stateData = useSelector((state) => state.state);

  const getStateData = () => {
    dispatch(stateCityAction.getState());
  };
  const getCategoriesList = useSelector((state) => state.getSwCategoryList);
  const getCategoriesListData = (data) => {
    dispatch(swInspectionAction.getSwCategoryList(data));
  };

  const getSubCategoriesList = useSelector(
    (state) => state.getSwSubCategoryList
  );
  const getSubCategoriesListData = (data) => {
    dispatch(swInspectionAction.getSwSubCategoryList(data));
  };

  const createSwQuestionSet = useSelector((state) => state.createSwQuestionSet);

  const createSwCategory = useSelector((state) => state.createSwCategory);
  const updateSwQuestionSet = useSelector((state) => state.updateSwQuestionSet);
  const createSwSubCategory = useSelector((state) => state.createSwSubCategory);

  const createSwQuestion = useSelector((state) => state.createSwQuestion);

  const [clearCategory, setClearCategory] = useState(false);
  const [clearStatus, setClearStatus] = useState(false);
  const [clearSubCategory, setClearSubCategory] = useState(false);
  const [clearQuestion, setClearQuestion] = useState(false);

  const showLoader = useSelector(
    (state) =>
      state.createSwQuestionSet?.isLoading ||
      state.updateSwQuestionSet?.isLoading ||
      state.getSwCategoryList?.isLoading ||
      state.getSwSubCategoryList?.isLoading ||
      state.getQuestionsList?.isLoading ||
      state.createSwCategory?.isLoading ||
      state.createSwSubCategory?.isLoading ||
      state.createSwQuestion?.isLoading ||
      state.state?.isLoading ||
      state.getSwReference?.isLoading
  );

  const onSubmit = (data) => {
    setSuccessScreens(true);
    setClearStatus(true);
    dispatch(swInspectionAction.createSwQuestionSet(data));
  };

  const onSetSubmit = (data) => {
    setSuccessScreens(true);
    dispatch(swInspectionAction.updateSwQuestionSet(data));
  };

  const onCategorySubmit = (data) => {
    setClearCategory(true);
    dispatch(swInspectionAction.createSwCategory(data));
  };

  const onSubCategorySubmit = (data) => {
    setClearSubCategory(true);
    dispatch(swInspectionAction.createSwSubCategory(data));
  };

  const onQuestionSubmit = (data) => {
    setClearQuestion(true);
    dispatch(swInspectionAction.createSwQuestion(data));
  };

  useEffect(() => {
    if (
      createSwCategory?.status === "SUCCESS" &&
      createSwCategory?.isLoading === false &&
      clearCategory
    ) {
      getCategoriesListData(defaultCatData);
      toast("Category Added successfully");
    }
  }, [createSwCategory]);

  useEffect(() => {
    if (
      createSwSubCategory?.status === "SUCCESS" &&
      createSwSubCategory?.isLoading === false &&
      clearSubCategory
    ) {
      getSubCategoriesListData(defaultSubCatData);
      toast("Sub Category Added successfully");
    }
  }, [createSwSubCategory]);

  useEffect(() => {
    dispatch(incpectionAction.clearCloneQuestionSet());
    dispatch(swInspectionAction.clearSwQuestionSet());
    getCategoriesListData(defaultCatData);
    getSubCategoriesListData(defaultSubCatData);
    getSwReferenceData(refData);
  }, []);

  useEffect(() => {
    if (
      createSwQuestionSet?.status === "SUCCESS" &&
      createSwQuestionSet?.isLoading === false &&
      clearStatus
    ) {
      toast("Inspection Name Added successfully");
    }
  }, [createSwQuestionSet]);

  useEffect(() => {
    if (
      createSwQuestion?.data?.responseMessages?.responseCode === "HBNG001" &&
      createSwQuestion?.status === "SUCCESS" &&
      createSwQuestion?.isLoading === false &&
      clearQuestion
    ) {
      toast("Questions Added successfully");
    }

    if (
      createSwQuestion?.data?.responseMessages?.responseCode === "HBNG002" &&
      createSwQuestion?.isLoading === false &&
      createSwQuestion?.status === "SUCCESS" &&
      clearQuestion
    ) {
      toast(createSwQuestion?.data?.responseMessages?.responseMessage);
    }
  }, [createSwQuestion]);

  useEffect(() => {
    getStateData();
  }, []);
  const successScreen = (id, successMsg, label1, label4,label2) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={successMsg}
        route={INSPECTION_FORM_LIST}
        label1={label1}
        label4={label4}
        label2={label2}
      ></SuccessScreen>
    );
  };

  return (
    <>
      <>{showLoader && <Loader />}</>
      {createSwQuestionSet.status === "SUCCESS" &&
      createSwQuestionSet.data &&
      successScreens ? (
        successScreen(
          createSwQuestionSet?.data?.questionSetId,
          "Inspection Form Created Successfully",
          "Set Id",
          "Inspection Form List in",
          " "
        )
      ) : updateSwQuestionSet.status === "SUCCESS" &&
        updateSwQuestionSet.data &&
        successScreens ? (
        successScreen(
          updateSwQuestionSet?.data?.questionSetId,
          "Inspection Form Updated Successfully",
          " ",
          "Inspection Form List in",
          "has been Updated"
        )
      ) : (
        <>
          <SetInspection
            stateData={stateData && stateData.data?.getState}
            getCategoriesList={getCategoriesList?.data?.result?.categoryList.filter(
              (x) => x.status
            )}
            getSubCategoriesList={getSubCategoriesList?.data?.result?.categoryList.filter(
              (x) => x.status
            )}
            getSubCategoriesListData={getSubCategoriesListData}
            onSubmit={onSubmit}
            onSetSubmit={onSetSubmit}
            createSwQuestionSet={createSwQuestionSet}
            editMode={editMode}
            questsetId={questsetId}
            onCategorySubmit={onCategorySubmit}
            onSubCategorySubmit={onSubCategorySubmit}
            onQuestionSubmit={onQuestionSubmit}
            createSwQuestion={createSwQuestion}
            getSwReference={getSwReference?.data?.insTypeMstList}
          />
          <ToastContainer />
        </>
      )}
    </>
  );
};

export default SetInspectionContainer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VehicleAccidentForm from "../components/vehicleAccidentForm";
import Carousel from "../../../library/custom/carouselBox/Carousel";
import CarouselData from "../../../constant/carouselConstant";
import * as incidentAction from "../../../redux/actions/incidentAction";
import * as vehicalAccidentAction from "../../../redux/actions/vehicalAccidentAction";
import * as userAction from "../../../redux/actions/userAction";
import * as stateCityAction from "../../../redux/actions/stateCityAction";
import Loader from "../../../library/common/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessScreen from "../../incidents/add-incident/component/successScreen";
import * as assetsAction from "../../../redux/actions/assetsAction";
import * as vehicleAction from "../../../redux/actions/vehiclesAction";

import {
  API_STATUS,
  INCIDENT_REPORT_ADDED,
  INCIDENT_REPORT_UPDATE,
} from "../../../constant/constant";
import {
  PERSONAL_INJURY,
  VEHICLE_ACCIDENT,
  UTILITY_DAMAGE,
  STOLEN_PROPERTY,
  NEAR_MISS,
  DASHBOARD,
  ACCESS_DENIED,
} from "../../../constant/routeContant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { logEvent, validateFileSize } from "../../../utils/helper";
import { fileUpload } from "../../../services/fileUploadService";
import { getReferenceData } from "../../../redux/actions/incpectionAction";

export default function AddVehicleContainer({ props }) {
  const location = useLocation();
  const existingCaseId = location?.state?.caseId;
  const incidentId = location?.state?.incidentId;
  const editMode = location?.state?.editMode;
  const [successScreens, setSuccessScreens] = useState(false);
  let navigate = useNavigate();
  const disptch = useDispatch();
  const incident = useSelector((state) => state.incident.data);
  const addVehicalData = useSelector((state) => state.addVehical.data);
  const isAllowed = checkPrivileges([1, 2, 3, 12]);
  const status = useSelector((state) => state.addVehical.status);
  const caseData = useSelector((state) => state.createdCase.data);
  const stateData = useSelector((state) => state.state.data);
  const cityData = useSelector((state) => state.city.data);
  const cityDataStatus = useSelector((state) => state.city);
  const searchOption = useSelector((state) => state.users.data);
  const regNumberDatas = useSelector((state) => state.getAssetDynomoList?.data);
  const regNumberDatasLoading = useSelector(
    (state) => state.getAssetDynomoList?.isLoading
  );
  const allUsers = useSelector((state) => state.allUsers.data);
  const [vehicleList, setvehicleList] = useState([]);

  const searchAllUsers = (searchType, searchText, projectId = 0) => {
    disptch(userAction.searchAllUsers(searchText, searchType, 0, projectId));
  };

  const isLoading = useSelector(
    (state) =>
      state.users.isLoading ||
      state.getRegNumberDat.isLoading ||
      state.getAssetDynomoList?.isLoading
  );

  const updateVehicleAccidentStatus = useSelector(
    (state) => state.updatedVehicleAccidentData.status
  );
  const updateVehicleAccidentData = useSelector(
    (state) => state.updatedVehicleAccidentData.data
  );

  const accidentReportData = useSelector(
    (state) => state.getAccidentReport.data
  );
  const accidentReportDataStatus = useSelector(
    (state) => state.getAccidentReport
  );

  const showLoader = useSelector(
    (state) =>
      state.incident.isLoading ||
      state.createdCase.isLoading ||
      state.addVehical.isLoading ||
      state.fileUpload.isLoading ||
      state.updatedVehicleAccidentData.isLoading ||
      state.getAccidentReport.isLoading
  );

  const { weather } = useSelector((state) => state.inspection);

  useEffect(() => {
    disptch(getReferenceData());
  }, []);

  const isSuccess = useSelector(
    (state) =>
      state.incident.status === API_STATUS.SUCCESS &&
      (existingCaseId
        ? existingCaseId
        : state.createdCase.status === API_STATUS.SUCCESS)
  );

  const [dataId, setDataId] = useState("");
  const addAndUpdateVehicleAccident = async (data) => {
    setDataId(data.vehicleAccident.incidentId);

    setSuccessScreens(true);
    if (data.vehicleAccident.incidentId === 0) {
      disptch(vehicalAccidentAction.vehicalAccident(data.vehicleAccident));
    } else {
      disptch(
        vehicalAccidentAction.updateVehicalAccident(data.vehicleAccident)
      );
    }
  };
  const getIncidentData = () => {
    disptch(incidentAction.getIncidentData());
  };

  const searchUsers = (searchType, searchText) => {
    disptch(userAction.searchUsers(searchType, searchText));
  };

  const getVehicalAccidentDetails = (caseId, incidentId) => {
    disptch(
      vehicalAccidentAction.getVehicalAccidentDetails(caseId, incidentId)
    );
  };

  const getStateData = () => {
    disptch(stateCityAction.getState());
  };

  const getRegNumberData = (searchText) => {
    // disptch(vehicalAccidentAction.getRegNumber(searchText));
    const type = 3;
    disptch(assetsAction.getAssetDynomoList({ searchText, type }));
  };

  useEffect(() => {
    if (!isAllowed) {
      navigate(DASHBOARD);
    }
    disptch(vehicalAccidentAction.clearVehicleAccident());
    if (location.state === "Vehicle Accident") {
      const value = {
        id: 2,
        title: "Vehicle Accident",
      };
      setcarouselValue(value);
    }
    if (existingCaseId && incidentId) {
      getVehicalAccidentDetails(existingCaseId, incidentId);
    }
    getStateData();
    getIncidentData();
  }, []);

  const [carouselValue, setcarouselValue] = useState({
    id: 2,
    title: "Vehicle Accident",
  });

  const handleOnChangeMethod = (value) => {
    if (!editMode) {
      setcarouselValue(value);
      if (value.id === 1) {
        navigate(PERSONAL_INJURY, {
          state: { caseId: existingCaseId, isDirect: true },
        });
      } else if (value.id === 2) {
        navigate(VEHICLE_ACCIDENT, { state: { caseId: existingCaseId } });
      } else if (value.id === 4) {
        navigate(UTILITY_DAMAGE, { state: { caseId: existingCaseId } });
      } else if (value.id === 3) {
        navigate(STOLEN_PROPERTY, { state: { caseId: existingCaseId } });
      } else if (value.id === 5) {
        navigate(NEAR_MISS, { state: { caseId: existingCaseId } });
      }
    }
  };

  const getCity = (stateId) => {
    disptch(stateCityAction.getCity(stateId));
  };

  const [fileError, setFileError] = useState(null);
  const [countError, setCountError] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [fileUploadData, setFileUploadData] = useState();

  const uploadFile = async (data) => {
    const validation = validateFileSize(data);
    if (!validation.isValid) {
      setFileError(validation.errorMessage);
      setCountError(countError + 1);
      return;
    }
    setIsLoader(true);
    fileUpload(data)
      .then((res) => {
        setIsLoader(false);
        const files = { files: res };
        setFileUploadData(files);
      })
      .catch((error) => {
        setCountError(countError + 1);
        setFileError("Error while uploading files.");
      });
  };

  const successScree = (id) => {
    logEvent(
      "Incident",
      editMode ? "Update Vehicle Accident" : "Add Vehicle Accident"
    );
    return (
      <SuccessScreen
        id={id}
        caseId={existingCaseId}
        isUpdate={dataId === 0 ? INCIDENT_REPORT_ADDED : INCIDENT_REPORT_UPDATE}
      ></SuccessScreen>
    );
  };

  useEffect(() => {
    if (regNumberDatas) {
      let arr = [];
      regNumberDatas.forEach((i) =>
        arr.push({
          vehicleId: i.key,
          regNumber: i.value,
        })
      );
      setvehicleList(arr);
    }
  }, [regNumberDatas]);

  const vehicleDetailsData = useSelector((state) => state?.getVehicleDetails);

  const getAssetDetails = (vehicleId) => {
    if (vehicleId)
      disptch(vehicleAction.getVehicleDetails(vehicleId.vehicleId));
  };
  return (
    <>
      {checkPrivileges([1, 2, 12]) ? (
        <>
          {status === API_STATUS.SUCCESS && addVehicalData && successScreens ? (
            successScree(addVehicalData?.incidentId)
          ) : updateVehicleAccidentStatus === API_STATUS.SUCCESS &&
            updateVehicleAccidentData &&
            successScreens ? (
            successScree(incidentId)
          ) : (
            <div className="dashboard-slider">
              <div>
                <div className="dashbord_heading">
                  <div className="insi-stitle">
                    <p>Select Incident Type</p>
                    <div className="caseId">
                      Case ID: EVE-{" "}
                      {existingCaseId ? existingCaseId : caseData?.caseId}
                    </div>
                  </div>
                </div>

                <Carousel
                  sliderItems={CarouselData}
                  activeSlide={carouselValue}
                  handleOnChange={handleOnChangeMethod}
                  edit={editMode ? true : false}
                />
              </div>
              <div className="loader-wrapper">
                {(showLoader || isLoader) && <Loader />}
              </div>
              <div>
                {isSuccess && (
                  <VehicleAccidentForm
                    incident={incident}
                    incidentId={incidentId ? incidentId : 0}
                    caseData={caseData}
                    caseId={existingCaseId ? existingCaseId : caseData?.caseId}
                    state={stateData && stateData?.getState}
                    searchOption={searchOption}
                    regNumberDatas={vehicleList ?? []}
                    accidentReportData={incidentId ? accidentReportData : ""}
                    getCity={getCity}
                    city={cityData && cityData?.cities}
                    uploadFile={uploadFile}
                    fileUploadData={fileUploadData}
                    onSubmit={addAndUpdateVehicleAccident}
                    editMode={editMode}
                    searchUsers={searchUsers}
                    searchVehicle={getRegNumberData}
                    isLoading={isLoading}
                    searchAllUsers={searchAllUsers}
                    allUsers={allUsers}
                    cityDataStatus={cityDataStatus}
                    accidentReportDataStatus={accidentReportDataStatus}
                    regNumberDatasLoading={regNumberDatasLoading}
                    getAssetDetails={getAssetDetails}
                    vehicleDetailsData={vehicleDetailsData}
                    fileError={fileError}
                    countError={countError}
                    weather={weather}
                  />
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}
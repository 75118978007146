import React, { useState } from "react";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import { TableLoader } from "../../../../library/common/Loader";
import {
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
  USER_ID,
  ROW_PER_PAGE_SIZE,
  CLIENT_SATATUS_FILTER,
} from "../../../../constant/constant";
import { useDispatch } from "react-redux";
import * as assetsAction from "../../../../redux/actions/assetsAction";
import ReportRowManipulte from "../../shared/ReportRowManipulate";
import NORow from "../../../../library/custom/noRow/noRow";
import { useNavigate } from "react-router-dom";
import { ADMIN_ADD_ASSETS } from "../../../../constant/routeContant";
import DialogBox from "../../../../library/common/DialogBox";
import { Grid } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormControl from "@mui/material/FormControl";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import FilterListIcon from "@mui/icons-material/FilterList";
import ServiceeFilterList from "../jha/serviceListFilter";
import downloadIcon from "../../../../assets/downloadIcon.svg";

const AssetTable = ({
  isAssetLoading,
  assetList,
  getAssetListData,
  filterShow,
  isProfile = false,
  divisionDropdown,
  isDivisionDropdown = true,
  assetCategory,
  isShowDowdownloadButton = true,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isTableLoading, setisTableLoading] = useState(false);

  const openFilter = Boolean(anchorEl);
  const id = openFilter ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [filterFormValue, setFilterFormValue] = useState({
    assetType: "",
    isActive: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const [division, setDivision] = useState(
    divisionDropdown ? divisionDropdown[0]?.key : 0
  );

  const handleEditOnclick = (rows) => {
    navigate(ADMIN_ADD_ASSETS, {
      state: { id: rows.row.id, editMode: true },
    });
  };
  const deletehandle = () => {
    setOpenModal(false);
    dispatch(assetsAction.deleteAsset(selectedRow));
  };

  const deleteAsset = (rows) => {
    setOpenModal(true);
    setSelectedRow(rows);
  };

  const columns = [
    { field: "assetName", headerName: "Asset Name", flex: 2 },
    { field: "id", headerName: "Asset ID", flex: 1 },
    { field: "divisionName", headerName: "Division", flex: 1.5 },
    { field: "startDate", headerName: "Start Date", flex: 1.5 },
    {
      field: "assetType",
      headerName: "Asset Type",
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <>{cellValues?.row?.assetType ? cellValues?.row?.assetType : "NA"}</>
        );
      },
    },

    { field: "status", headerName: "Status", flex: 1 },

    {
      field: "icon",
      renderHeader: () =>
        filterShow ? (
          <SettingTable
            noOfRecords={assetList?.itemsCount ?? 0}
            numberOfItemsPerPage={pageSize}
            incidentLists={assetList?.assetData ?? []}
            fileNameXL="assetList"
          />
        ) : (
          ""
        ),
      width: 68,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return filterShow ? (
          <ReportRowManipulte
            rows={rows}
            deleteOnClick={() => {
              deleteAsset(rows.id);
            }}
            editOnClick={() => handleEditOnclick(rows)}
            deleted={checkPrivileges([37, 12])}
            edit={checkPrivileges([37, 38, 12])}
            deleteLabel="Delete Asset"
            editLabel="Edit Asset"
          />
        ) : (
          ""
        );
      },
    },
  ];

  const handleSearch = (data) => {
    setAnchorEl(null);
    getAssetListData(data);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      page: 1,
      divisionId: division === 1 ? 0 : division,
      pageSize: pageSize,
      compnayName: "",
      isSendMail: false,
      search: event.target.value,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      statusIds: filterFormValue.isActive === 2 ? 0 : filterFormValue.isActive,
      assetType:
        filterFormValue.assetType === "" ? 0 : filterFormValue.assetType,
    };
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getAssetListData(data);
  };
  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      divisionId: division === 1 ? 0 : division,
      pageSize: pageSize,
      compnayName: "",
      isSendMail: false,
      search: search,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      assetType:
        filterFormValue.assetType === "" ? 0 : filterFormValue.assetType,
      statusIds: filterFormValue.isActive === 2 ? 0 : filterFormValue.isActive,
    };
    getAssetListData(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      page: 1,
      divisionId: division === 1 ? 0 : division,
      pageSize: newPazeSize,
      compnayName: "",
      isSendMail: false,
      search: search,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      statusIds: filterFormValue.isActive === 2 ? 0 : filterFormValue.isActive,
      assetType:
        filterFormValue.assetType === "" ? 0 : filterFormValue.assetType,
    };

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getAssetListData(data);
  };
  const onChangeDivision = (e) => {
    setDivision(e.target.value);
    setSearch("");
    const data = {
      page: 1,
      divisionId: e.target.value === 1 ? 0 : e.target.value,
      pageSize: pageSize,
      compnayName: "",
      isSendMail: false,
      search: search,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      statusIds: filterFormValue.isActive === 2 ? 0 : filterFormValue.isActive,
      assetType:
        filterFormValue.assetType === "" ? 0 : filterFormValue.assetType,
    };

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getAssetListData(data);
    setPage(1);
  };

  function handleFilterClear(params) {
    setFilterFormValue({
      assetType: 0,
      isActive: "",
    });

    setPage(1);
    const data = {
      page: 1,
      divisionId: division === 1 ? 0 : division,
      pageSize: pageSize,
      compnayName: "",
      isSendMail: false,
      search: "",
      userId: isProfile ? parseInt(USER_ID()) : 0,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      statusIds: "",
      assetType: 0,
    };
    setSearch("");
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getAssetListData(data);
    setAnchorEl(null);
    window.close();
  }

  function handleFilterApply(params) {
    setPage(1);
    const data = {
      page: 1,
      divisionId: division === 1 ? 0 : division,
      pageSize: pageSize,
      compnayName: "",
      isSendMail: false,
      search: search,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      statusIds: filterFormValue.isActive === 2 ? 0 : filterFormValue.isActive,
      assetType:
        filterFormValue.assetType === "" ? 0 : filterFormValue.assetType,
    };
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getAssetListData(data);
    setAnchorEl(null);
    window.close();
  }
  const sendEmail = () => {
    const data = {
      page: 1,
      divisionId: division === 1 ? 0 : division,
      pageSize: pageSize,
      compnayName: "",
      isSendMail: true,
      search: search,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      statusIds: filterFormValue.isActive === 2 ? 0 : filterFormValue.isActive,
      assetType:
        filterFormValue.assetType === "" ? 0 : filterFormValue.assetType,
    };
    getAssetListData(data);
  };

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          {openModal && (
            <DialogBox
              open={openModal}
              rows={[]}
              header={"Confirm Deletion"}
              deletehandle={deletehandle}
              handleCloseModal={() => setOpenModal(false)}
              content={"Are you sure you want to delete this Asset?"}
              view
            />
          )}
        </div>
        <div className="grid_main_body divisionWithsearch">
          <div className="div-asset-filter-container">
            <div className="div-filter-left">
              <Grid container>
                <Grid item lg={5} xs={12} sm={6} md={5} mr={2} pt={3}>
                  <div className="filter_of_caselist">
                    <div className="searchcaseid">
                      <label htmlFor="search">
                        <span>
                          <SearchIcon />
                        </span>
                        <div>
                          <input
                            id="search"
                            placeholder="Search"
                            type="text"
                            value={search}
                            className=""
                            onChange={handleautoSearch}
                            autoComplete="off"
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                </Grid>

                <Grid item lg={2} xs={12} sm={6} md={3} mr={2} pt={3}>
                  <div className="filter_of_caselist">
                    <div className="filter_btn divisionWithFilter">
                      <button
                        aria-describedby={id}
                        variant="contained"
                        type="button"
                        name="btn"
                        onClick={(e) => handleClick(e)}
                      >
                        <FilterListIcon id="icn" /> Filters
                      </button>

                      <ServiceeFilterList
                        id={id}
                        open={openFilter}
                        anchorEl={anchorEl}
                        handleClose={() => {
                          setAnchorEl(null);
                          window.close();
                        }}
                        handleApply={() => handleFilterApply()}
                        firstLabel="Asset Type"
                        secondLabel="Status"
                        firstLebelFilter={assetCategory}
                        secondLebelFilter={CLIENT_SATATUS_FILTER}
                        firstLebelValue={filterFormValue.assetType}
                        secondLebelValue={filterFormValue.isActive}
                        firstLebelOnChanges={(e) =>
                          setFilterFormValue({
                            ...filterFormValue,
                            assetType: e.target.value,
                          })
                        }
                        secondLebelOnChanges={(e) =>
                          setFilterFormValue({
                            ...filterFormValue,
                            isActive: e.target.value,
                          })
                        }
                        handleClear={() => handleFilterClear()}
                      />
                    </div>
                  </div>
                </Grid>
                {isDivisionDropdown && (
                  <Grid item lg={4} xs={12} sm={4} md={3}>
                    <FormControl fullWidth>
                      <FormLabels label="Select Division" isRequired={false} />
                      <SelectMenu
                        disabledDefault={true}
                        listData={divisionDropdown}
                        value={division === 0 ? 1 : division}
                        onchangehandler={(e) => onChangeDivision(e)}
                        isgroupplaceholder={true}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </div>
            {isShowDowdownloadButton && (
              <div className="div-filter-right-download" onClick={sendEmail}>
                <span>
                  <img
                    src={downloadIcon}
                    className="DownLoadLogo"
                    alt="DownLoad Logo"
                  />
                </span>
                Download Data
              </div>
            )}
          </div>
          {isTableLoading ? (
            ""
          ) : (
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <GridTable
                  getRowId={(r) => r.id}
                  rows={assetList?.assetData ?? []}
                  rowCount={assetList?.itemsCount ?? 0}
                  columns={columns}
                  loading={isAssetLoading}
                  page={page}
                  pagination
                  pageSize={pageSize}
                  rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                  onPageSizeChange={handlePageSizeChange}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default AssetTable;

import React, { useRef, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import "./addReport.scss";
import SwCommonForm from "./addReport/swCommonForm";
import AddIcon from "@mui/icons-material/Add";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import FormControl from "@mui/material/FormControl";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import Buttons from "../../../../library/custom/button/Button";
import PreviousOpenItems from "./addReport/previousOpenItems";
import { InputField } from "../../../../library/custom/textBox/InputField";
import TextEditor from "../../../../library/custom/textEditor/TextEditor";
import commonImages from "../../../../assets";
import SwIndividualCommonForm from "./addReport/swIndividualCommonForm";
import Loader from "../../../../library/common/Loader";
import NorthCarolina from "./addReport/northCarolina";
import ThreeQuestion from "./addReport/threeQuestion";
import { useThemeContext } from "../../../../context";

const AddReport = ({
  questionSetId,
  swQuestionSetDetail,
  getInTyFeByInTyList,
  handleRadioType,
  formchangeHnadle,
  formValue,
  setFormValue,
  submitData,
  deleteform,
  saveData,
  contractorsignatureUpload,
  selectedContractorSignature,
  signatureError,
  deleteHandler,
  isProjectName,
  isCustomerName,
  isProjectNo,
  isPrimaryInspector,
  isDateofInspection,
  isTimeofInspection,
  isWeatherAtTimeofInspection,
  isSoilTypeId,
  isStageOfConstruction,
  isSampleTaken,
  isPrimaryPermitteeName,
  isCurrentApproximateDisturbedAcreage,
  isNPDESTrackingNumber,
  getOpenItem,
  openItemHandler,
  previousItem,
  getSwReference,
  isSignatureProfile,
  hiddenAttendeeSignatureInput,
  addMediaNotAccUpload,
  setImageCheck,
  listPermitte,
  mapId,
  handleAllProjectSearch,
  isProjectLoading,
  allProjects,
  onChangeProjects,
  projects,
  inspectionQuestionSetList,
  inspectionTypeId,
  handleInspectionTypeSetChange,
  handleQuestionSetChange,
  allClients,
  clients,
  searchClient,
  onChangeClients,
  isSearchClientLoading,
  firstClientError,
  userInfo,
  inspectionHeaderDetails,
  constructionActivityId,
  handleConstructionActivitySetChange,
  isConstructionActivityId,
  frequencyValue,
  handleFrequencyChange,
  isMediaAllowed,
  isGeorgiaState,
  projectStatus,
  setProjectStatus,
  alsoSubmitRoutineInspection,
  handleChangeDefault,
  characterLimit,
  reportId,
  isRainFallError,
  isDirectIns,
  editMode,
  isFluid,
  frequencyInDays,
  dateRangeHandler,
  dateRangeError,
  errorFrequencyInDays,
  fileError,
  signaureFileError,
  signaureFileErrorMessage,
  isNorthCarolina,
  swInspectionConfQuestionAns,
  setSwInspectionConfQuestionAns,
  swRainFallData,
  swInspectionRainfallData,
  setSwInspectionRainfallData,
  swInspectionCurrentProjectPhase,
  setSwInspectionCurrentProjectPhase,
  swInspectionGroundStabilization,
  setSwInspectionGroundStabilization,
  swInspectionObservation,
  setSwInspectionObservation,
  swInspectionLotDisturbance,
  setSwInspectionLotDisturbance,
  formchangeThreeHnadle,
  frequencyValueError,
  disabledCheck,
  insStatus,
  weather
}) => {
  const { mode } = useThemeContext();
  const hiddenSignatureInput = useRef(null);
  const [accordionLoader, setaccordionLoader] = useState(false);
  const [allExpanded, setallExpanded] = useState(false);
  const [frequency, setFrequency] = useState();

  // temporary base change
  const [InspectionType, setInspectionType] = useState();

  useEffect(() => {
    if (isGeorgiaState && getSwReference?.insfrequencyList) {
      const newFrequency = getSwReference?.insfrequencyList?.filter(
        (item) => item.key !== 1
      );
      setFrequency(newFrequency);

      // temporary base change
      const newInspectionType = getSwReference?.insTypeMstList?.filter(
        (item) => item.key !== 2
      );
      setInspectionType(newInspectionType);
    } else {
      setFrequency(getSwReference?.insfrequencyList);

      // temporary base change
      setInspectionType(getSwReference?.insTypeMstList);
    }
  }, [isGeorgiaState, getSwReference?.insfrequencyList]);

  useEffect(() => {
    if (isFluid && getSwReference?.insfrequencyList) {
      const data = getSwReference?.insfrequencyList?.filter(
        (item) => item.key !== 1
      );
      setFrequency(data);
    } else {
      const data = getSwReference?.insfrequencyList?.filter(
        (item) => item.key !== 7
      );
      setFrequency(data);
    }
  }, [isFluid, getSwReference?.insfrequencyList]);

  const [expandedItems, setExpandedItems] = useState([]);
  function handleAccordionExpand(index) {
    setExpandedItems((prevExpanded) => {
      if (prevExpanded.includes(index)) {
        return prevExpanded?.filter((item) => item !== index);
      } else {
        return [...prevExpanded, index];
      }
    });
  }

  const rainFallHandler = (value, insRainFallData) => {
    const confRainFallValue = value;

    setSwInspectionRainfallData((prevState) => {
      const existingIndex = prevState.findIndex(
        (item) => item.precipDateFormat === insRainFallData.precipDateFormat
      );
      if (existingIndex !== -1) {
        const updatedData = [...prevState];
        updatedData[existingIndex] = {
          ...updatedData[existingIndex],
          day: insRainFallData?.precipDateFormat,
          rainfallAmt: insRainFallData?.evenTrackPrecipAmt,
          notes: confRainFallValue,
        };
        return updatedData;
      }
    });
  };
  const initials = formValue?.primaryInspector
    ? formValue?.primaryInspector
        ?.split(" ")
        ?.map((word) => word.charAt(0).toUpperCase())
        ?.join("")
    : "";

  return (
    <div className="swAddReport">
      {!mapId && (
        <SwIndividualCommonForm
          formValue={formValue}
          setFormValue={setFormValue}
          handleAllProjectSearch={handleAllProjectSearch}
          isProjectLoading={isProjectLoading}
          allProjects={allProjects}
          projects={projects}
          onChangeProjects={onChangeProjects}
          getSwReference={getSwReference}
          inspectionQuestionSetList={inspectionQuestionSetList}
          inspectionTypeId={inspectionTypeId}
          handleInspectionTypeSetChange={handleInspectionTypeSetChange}
          questionSetId={questionSetId}
          handleQuestionSetChange={handleQuestionSetChange}
          allClients={allClients}
          clients={clients}
          searchClient={searchClient}
          onChangeClients={onChangeClients}
          isSearchClientLoading={isSearchClientLoading}
          firstClientError={firstClientError}
          frequencyValue={frequencyValue}
          handleFrequencyChange={handleFrequencyChange}
          frequencyInDays={frequencyInDays}
          dateRangeHandler={dateRangeHandler}
          dateRangeError={dateRangeError}
          errorFrequencyInDays={errorFrequencyInDays}
          frequency={frequency}
          // temporary base change
          InspectionType={InspectionType}
          frequencyValueError={frequencyValueError}
          editMode={editMode}
          disabledCheck={disabledCheck}
        />
      )}

      {questionSetId && swQuestionSetDetail && (
        <>
          <SwCommonForm
            formValue={formValue}
            setFormValue={setFormValue}
            getInTyFeByInTyList={getInTyFeByInTyList}
            isProjectName={isProjectName}
            isCustomerName={isCustomerName}
            isProjectNo={isProjectNo}
            isPrimaryInspector={isPrimaryInspector}
            isDateofInspection={isDateofInspection}
            isTimeofInspection={isTimeofInspection}
            isWeatherAtTimeofInspection={isWeatherAtTimeofInspection}
            isSoilTypeId={isSoilTypeId}
            isStageOfConstruction={isStageOfConstruction}
            isSampleTaken={isSampleTaken}
            isPrimaryPermitteeName={isPrimaryPermitteeName}
            isCurrentApproximateDisturbedAcreage={
              isCurrentApproximateDisturbedAcreage
            }
            isNPDESTrackingNumber={isNPDESTrackingNumber}
            getSwReference={getSwReference}
            listPermitte={listPermitte}
            mapId={mapId}
            inspectionHeaderDetails={inspectionHeaderDetails}
            constructionActivityId={constructionActivityId}
            handleConstructionActivitySetChange={
              handleConstructionActivitySetChange
            }
            isConstructionActivityId={isConstructionActivityId}
            projectStatus={projectStatus}
            setProjectStatus={setProjectStatus}
            alsoSubmitRoutineInspection={alsoSubmitRoutineInspection}
            handleChangeDefault={handleChangeDefault}
            inspectionTypeId={inspectionTypeId}
            characterLimit={characterLimit}
            isRainFallError={isRainFallError}
            isDirectIns={isDirectIns}
            editMode={editMode}
            frequencyValue={frequencyValue}
            handleFrequencyChange={handleFrequencyChange}
            frequency={frequency}
            frequencyInDays={frequencyInDays}
            dateRangeHandler={dateRangeHandler}
            dateRangeError={dateRangeError}
            errorFrequencyInDays={errorFrequencyInDays}
            frequencyValueError={frequencyValueError}
            disabledCheck={disabledCheck}
            weather={weather}
          />
          {isNorthCarolina && (
            <Grid item md={12} className="northCarolinaOverride" mt={3}>
              <div className="commonTableBoxNorth">
                <div className="commonTableBoxNorthTitle">
                  7 Day Rainfall Data
                </div>
                <table>
                  <tr className="tableHead">
                    <th>Day/Date </th>
                    <th>Rain Amount (in)</th>
                    <th>Initials</th>
                    <th className="fourty">
                      Notes:
                      <div className="smallText">
                        Daily Rainfall Required. Except for Holidays or
                        Weekends.If no rain, indicate with a “zero”
                      </div>
                    </th>
                  </tr>
                  {swRainFallData?.map((rainFall) => {
                    const onchangeValue = swInspectionRainfallData.find(
                      (item) =>
                        item.precipDateFormat === rainFall.precipDateFormat
                    )?.notes;

                    return (
                      <tr className="tableBody" key={rainFall?.precipId}>
                        <td>{rainFall?.precipDateFormat || rainFall.day}</td>
                        <td>
                          {(
                            Math.floor(rainFall?.evenTrackPrecipAmt * 100) / 100
                          )?.toFixed(2)}
                        </td>
                        <td>{initials}</td>
                        <td>
                          <InputField
                            isRequired={false}
                            type="text"
                            placeholder={"Enter Here..."}
                            value={onchangeValue}
                            onChange={(e) =>
                              rainFallHandler(e.target.value, rainFall)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </Grid>
          )}

          <Grid container justifyContent={"right"}>
            <Buttons
              varientContained={true}
              label={allExpanded ? "Collapse All" : "Expand All"}
              onClick={() => {
                setaccordionLoader(true);
                setallExpanded(!allExpanded);
                setTimeout(() => {
                  setaccordionLoader(false);
                }, 300);
              }}
              // disabled={isSubmit ? true : false}
            />
          </Grid>
          {accordionLoader ? (
            <Loader />
          ) : (
            <Grid mt={5}>
              <div>
                {swQuestionSetDetail?.map((category, categoryIndex) => (
                  <>
                    <Accordion
                      key={categoryIndex}
                      className="swAccordianParent"
                      defaultExpanded={allExpanded}
                    >
                      <AccordionSummary
                        className="swAccordian"
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <div className="cateTitleWIns">
                          <Typography className="accoTitle">
                            {category.value}
                          </Typography>

                          <div className="catButtonBox">
                            <div className="cats">
                              {previousItem?.some((prevItem) =>
                                category?.listInspectionSubCategory?.some(
                                  (subCategory) =>
                                    subCategory.listInspectionQuestionAnswer?.some(
                                      (item) =>
                                        previousItem.some(
                                          (secItem) =>
                                            parseInt(prevItem.questionId) ===
                                              parseInt(item.key) ||
                                            parseInt(secItem.questionId) ===
                                              parseInt(item.key)
                                        )
                                    )
                                )
                              ) ? (
                                <>
                                  <div className="openInfoContainer">
                                    <div className="openInfoInfo">
                                      <img
                                        src={commonImages?.RedInfo}
                                        alt=""
                                        className=""
                                      />
                                      <div className="openInfoMsg">
                                        There are some Open items in categories
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="inspectPersantage">
                              {category.howManyCat}/
                              {category.listInspectionSubCategory?.length}{" "}
                              Inspected ({category.catPercent?.toFixed(2)}%)
                            </div>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {category.listInspectionSubCategory.map(
                          (subcategory, subcategoryIndex) => (
                            <Accordion
                              key={subcategoryIndex}
                              className="swAccordianChild"
                              defaultExpanded={allExpanded}
                              onChange={() =>
                                handleAccordionExpand(subcategory.value)
                              }
                              expanded={
                                expandedItems?.includes(subcategory.value) ||
                                allExpanded
                              }
                            >
                              <AccordionSummary
                                className="swChildAccordian"
                                expandIcon={<ExpandMoreIcon />}
                              >
                                <div className="subCatButton">
                                  <Typography className="accoTitle">
                                    {subcategory.value}
                                  </Typography>
                                  <div className="subCatButtonBox">
                                    <div>
                                      {previousItem?.some((prevItem) =>
                                        subcategory?.listInspectionQuestionAnswer?.some(
                                          (item) =>
                                            prevItem.questionId === item.key
                                        )
                                      ) ? (
                                        <div className="openInfoContainer">
                                          <div className="openInfoInfo">
                                            <img
                                              src={commonImages?.RedInfo}
                                              alt=""
                                              className=""
                                            />
                                            <div className="openInfoMsg">
                                              There are some Open items in
                                              sub-categories
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="inspectPersantage">
                                      {subcategory.howManySub}/
                                      {
                                        subcategory.listInspectionQuestionAnswer
                                          .length
                                      }{" "}
                                      Inspected (
                                      {subcategory.subPercent?.toFixed(2)}
                                      %)
                                    </div>
                                  </div>
                                </div>

                                {subcategory?.listInspectionQuestionAnswer?.some(
                                  (x) => x.isTextResponse
                                ) ||
                                previousItem?.some((prevItem) =>
                                  subcategory?.listInspectionQuestionAnswer?.some(
                                    (item) => prevItem.questionId === item.key
                                  )
                                ) ? (
                                  ""
                                ) : (
                                  <div className="posChange">
                                    {(subcategory?.showBth && allExpanded) ||
                                    (subcategory?.showBth &&
                                      expandedItems.includes(
                                        subcategory.value
                                      )) ? (
                                      <div className="subCatButtonBox">
                                        <div
                                          className="allacceptbleBtn"
                                          onClick={(e) =>
                                            handleRadioType(
                                              null,
                                              null,
                                              subcategory,
                                              category,
                                              "allAcceptable",
                                              e
                                            )
                                          }
                                        >
                                          All Acceptable
                                        </div>

                                        <div
                                          className="allNotapplicableBtn"
                                          onClick={(e) =>
                                            handleRadioType(
                                              null,
                                              null,
                                              subcategory,
                                              category,
                                              "notApplicable",
                                              e
                                            )
                                          }
                                        >
                                          All N/A
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                )}
                              </AccordionSummary>

                              <AccordionDetails>
                                <ul>
                                  {subcategory.listInspectionQuestionAnswer.map(
                                    (question, questionIndex) => (
                                      <>
                                        <li
                                          key={questionIndex}
                                          className="accordianQuestionBox"
                                        >
                                          <div className="quesTitle">
                                            {question.quesNo}
                                            {". "}
                                            {question.value}
                                          </div>
                                          {question?.responseType === 2 ? (
                                            <div className="questionFreeText">
                                              <TextEditor
                                                value={
                                                  question?.textValueQuestion
                                                }
                                                placeholder={"Enter Here..."}
                                                name="freeText"
                                                onChange={(e) =>
                                                  handleRadioType(
                                                    e,
                                                    question,
                                                    subcategory,
                                                    category,
                                                    "questionV"
                                                  )
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <>
                                              <div>
                                                <div className="radioContainer">
                                                  <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    className="radioGroup"
                                                  >
                                                    <RadioButton
                                                      checked={
                                                        question.radioSelectiovalue ==
                                                        "1"
                                                      }
                                                      value={"1"}
                                                      label="Acceptable"
                                                      onChange={(e) =>
                                                        handleRadioType(
                                                          e.target.value,
                                                          question,
                                                          subcategory,
                                                          category,
                                                          "radio"
                                                        )
                                                      }
                                                      disabled={
                                                        previousItem?.length >
                                                          0 &&
                                                        previousItem?.some(
                                                          (x) =>
                                                            x.questionId ===
                                                            question.key
                                                        )
                                                      }
                                                    ></RadioButton>

                                                    <RadioButton
                                                      checked={
                                                        question.radioSelectiovalue ==
                                                        "2"
                                                      }
                                                      value={"2"}
                                                      label="Not Acceptable"
                                                      onChange={(e) =>
                                                        handleRadioType(
                                                          e.target.value,
                                                          question,
                                                          subcategory,
                                                          category,
                                                          "radio"
                                                        )
                                                      }
                                                    ></RadioButton>
                                                    <RadioButton
                                                      checked={
                                                        question.radioSelectiovalue ==
                                                        3
                                                      }
                                                      value={3}
                                                      label="N/A"
                                                      onChange={(e) =>
                                                        handleRadioType(
                                                          e.target.value,
                                                          question,
                                                          subcategory,
                                                          category,
                                                          "radio"
                                                        )
                                                      }
                                                      disabled={
                                                        previousItem?.length >
                                                          0 &&
                                                        previousItem?.some(
                                                          (x) =>
                                                            x.questionId ===
                                                            question.key
                                                        )
                                                      }
                                                    ></RadioButton>
                                                  </RadioGroup>
                                                </div>
                                                {(question.radioSelectiovalue ==
                                                  "1" ||
                                                  question.radioSelectiovalue ==
                                                    "3") &&
                                                question.responseType === 3 ? (
                                                  <>
                                                    <div className="radioContainer">
                                                      <ThreeQuestion
                                                        formchangeThreeHnadle={
                                                          formchangeThreeHnadle
                                                        }
                                                        question={question}
                                                        subcategory={
                                                          subcategory
                                                        }
                                                        category={category}
                                                      />
                                                    </div>
                                                  </>
                                                ) : (
                                                  ""
                                                )}

                                                {question.radioSelectiovalue ==
                                                "2" ? (
                                                  <>
                                                    {question.notAcceptableList
                                                      ?.length !== 0 &&
                                                      question.notAcceptableList?.map(
                                                        (
                                                          notAppce,
                                                          notAppceIndex
                                                        ) => {
                                                          return (
                                                            <>
                                                              <Grid
                                                                container
                                                                className="itemContainer"
                                                                key={
                                                                  notAppceIndex
                                                                }
                                                              >
                                                                <Grid
                                                                  md={12}
                                                                  xs={12}
                                                                  item
                                                                  mt={1}
                                                                >
                                                                  <div
                                                                    onClick={() =>
                                                                      deleteform(
                                                                        notAppce?.id,
                                                                        question,
                                                                        subcategory,
                                                                        category,
                                                                        "deleteItem"
                                                                      )
                                                                    }
                                                                    className="removeRepetBox"
                                                                  >
                                                                    X Remove
                                                                    Items
                                                                  </div>

                                                                  <FormControl
                                                                    fullWidth
                                                                  >
                                                                    <FormLabels
                                                                      label="Item Found"
                                                                      isRequired={
                                                                        true
                                                                      }
                                                                    />
                                                                    <SelectMenu
                                                                      placeholder="Please Select"
                                                                      listData={
                                                                        question?.options ??
                                                                        []
                                                                      }
                                                                      value={
                                                                        notAppce?.itemFoundKey ??
                                                                        null
                                                                      }
                                                                      onchangehandler={(
                                                                        e
                                                                      ) =>
                                                                        formchangeHnadle(
                                                                          e
                                                                            .target
                                                                            .value,
                                                                          question,
                                                                          subcategory,
                                                                          category,
                                                                          "itemFound",
                                                                          notAppce
                                                                        )
                                                                      }
                                                                    />
                                                                  </FormControl>
                                                                  {notAppce?.itemFoundError && (
                                                                    <div className="error">
                                                                      Please
                                                                      select
                                                                      this
                                                                      required
                                                                      field.
                                                                    </div>
                                                                  )}
                                                                </Grid>
                                                                {/* {question?.itemFound} */}
                                                                {notAppce?.itemFoundValue ===
                                                                  "Other" && (
                                                                  <Grid
                                                                    md={12}
                                                                    xs={12}
                                                                    item
                                                                    mt={2}
                                                                  >
                                                                    <FormLabels label="Item Details" />

                                                                    <TextEditor
                                                                      value={
                                                                        notAppce?.itemDetails
                                                                      }
                                                                      placeholder={
                                                                        "Enter Here..."
                                                                      }
                                                                      name="freeText"
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        formchangeHnadle(
                                                                          e,
                                                                          question,
                                                                          subcategory,
                                                                          category,
                                                                          "textArea",
                                                                          notAppce
                                                                        )
                                                                      }
                                                                    />
                                                                  </Grid>
                                                                )}

                                                                <Grid
                                                                  md={12}
                                                                  xs={12}
                                                                  item
                                                                  mt={2}
                                                                >
                                                                  <div className="locationContainer">
                                                                    <FormControl
                                                                      fullWidth
                                                                    >
                                                                      <FormLabels
                                                                        label="Add Location Details"
                                                                        isRequired={
                                                                          true
                                                                        }
                                                                      />
                                                                    </FormControl>
                                                                    <div className="locationInput">
                                                                      <Grid
                                                                        container
                                                                        className="locationInputBox"
                                                                      >
                                                                        <Grid
                                                                          md={5}
                                                                          xs={
                                                                            12
                                                                          }
                                                                          item
                                                                        >
                                                                          <FormControl
                                                                            fullWidth
                                                                            className="mediaInputContainer"
                                                                          >
                                                                            <InputField
                                                                              fullWidth={
                                                                                true
                                                                              }
                                                                              type="text"
                                                                              value={
                                                                                notAppce?.addLocationText
                                                                              }
                                                                              onChange={(
                                                                                e
                                                                              ) =>
                                                                                formchangeHnadle(
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                                  question,
                                                                                  subcategory,
                                                                                  category,
                                                                                  "textFiled",
                                                                                  notAppce
                                                                                )
                                                                              }
                                                                              placeholder={
                                                                                "Enter Text Here..."
                                                                              }
                                                                              className="locationInputText"
                                                                            />
                                                                          </FormControl>
                                                                        </Grid>
                                                                        <Grid
                                                                          md={5}
                                                                          xs={
                                                                            12
                                                                          }
                                                                          item
                                                                          className="addLocationCustomButton"
                                                                        >
                                                                          <Buttons
                                                                            aria-describedby="dd"
                                                                            variant="contained"
                                                                            type="button"
                                                                            name="btn"
                                                                            label={
                                                                              "Add Location"
                                                                            }
                                                                            onClick={(
                                                                              e
                                                                            ) =>
                                                                              formchangeHnadle(
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                                question,
                                                                                subcategory,
                                                                                category,
                                                                                "pushData",
                                                                                notAppce
                                                                              )
                                                                            }
                                                                            id="btnAddEquipment"
                                                                            varientAddIconBlue={
                                                                              true
                                                                            }
                                                                          ></Buttons>
                                                                        </Grid>
                                                                      </Grid>
                                                                    </div>

                                                                    {notAppce?.addLocatioList?.map(
                                                                      (
                                                                        noteee,
                                                                        noteeeIndex
                                                                      ) => {
                                                                        return (
                                                                          <Grid
                                                                            container
                                                                            key={
                                                                              noteee?.id
                                                                            }
                                                                          >
                                                                            <Grid
                                                                              md={
                                                                                7
                                                                              }
                                                                              xs={
                                                                                12
                                                                              }
                                                                              item
                                                                            >
                                                                              <div className="locationListBox">
                                                                                <div className="locationList">
                                                                                  {noteee.textVaue ? (
                                                                                    <>
                                                                                      <div className="locationTitile">
                                                                                        <span>
                                                                                          {
                                                                                            noteee.textVaue
                                                                                          }
                                                                                        </span>
                                                                                        <div
                                                                                          className="removeImg"
                                                                                          onClick={() =>
                                                                                            deleteHandler(
                                                                                              category,
                                                                                              subcategory,
                                                                                              question,
                                                                                              notAppce,
                                                                                              noteee,
                                                                                              "deleteText"
                                                                                            )
                                                                                          }
                                                                                        >
                                                                                          x
                                                                                        </div>
                                                                                      </div>
                                                                                      {noteee?.imgUrl && (
                                                                                        <div className="locationImg">
                                                                                          <img
                                                                                            alt=""
                                                                                            src={
                                                                                              noteee?.imgUrl
                                                                                            }
                                                                                            className="showUploadedImg"
                                                                                          />
                                                                                          <div
                                                                                            className="removeImg"
                                                                                            onClick={() =>
                                                                                              deleteHandler(
                                                                                                category,
                                                                                                subcategory,
                                                                                                question,
                                                                                                notAppce,
                                                                                                noteee,
                                                                                                "deleteImg"
                                                                                              )
                                                                                            }
                                                                                          >
                                                                                            x
                                                                                          </div>
                                                                                        </div>
                                                                                      )}
                                                                                      {isMediaAllowed &&
                                                                                        !noteee?.imgUrl && (
                                                                                          <div className="addMedia">
                                                                                            <input
                                                                                              type="file"
                                                                                              ref={(
                                                                                                el
                                                                                              ) =>
                                                                                                (hiddenAttendeeSignatureInput.current[
                                                                                                  noteee?.id
                                                                                                ] =
                                                                                                  el)
                                                                                              }
                                                                                              onChange={(
                                                                                                event
                                                                                              ) => {
                                                                                                addMediaNotAccUpload(
                                                                                                  event,
                                                                                                  question,
                                                                                                  subcategory,
                                                                                                  category,
                                                                                                  notAppce,
                                                                                                  noteee
                                                                                                );
                                                                                              }}
                                                                                              style={{
                                                                                                display:
                                                                                                  "none",
                                                                                              }}
                                                                                              accept="image/*"
                                                                                            />
                                                                                            <div
                                                                                              className="multipleIconContainer"
                                                                                              onClick={() => {
                                                                                                hiddenAttendeeSignatureInput.current[
                                                                                                  noteee
                                                                                                    ?.id
                                                                                                ].click();
                                                                                                setImageCheck(
                                                                                                  false
                                                                                                );
                                                                                              }}
                                                                                            >
                                                                                              <div className="multipleIconImg">
                                                                                                <img
                                                                                                  src={
                                                                                                    mode ===
                                                                                                    "dark"
                                                                                                      ? commonImages.multipleMediaDark
                                                                                                      : commonImages?.multipleMedia
                                                                                                  }
                                                                                                  alt="icon"
                                                                                                />
                                                                                              </div>
                                                                                              <div>
                                                                                                Upload
                                                                                                Media
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        )}
                                                                                    </>
                                                                                  ) : null}
                                                                                </div>
                                                                                {noteee?.uniqeFileErrorMessage &&
                                                                                  !noteee?.imgUrl && (
                                                                                    <Grid
                                                                                      className="error"
                                                                                      mt={
                                                                                        1
                                                                                      }
                                                                                    >
                                                                                      {
                                                                                        fileError
                                                                                      }
                                                                                    </Grid>
                                                                                  )}
                                                                              </div>
                                                                            </Grid>
                                                                          </Grid>
                                                                        );
                                                                      }
                                                                    )}
                                                                  </div>
                                                                  {notAppce?.addLocationDetailError && (
                                                                    <div className="error">
                                                                      Please
                                                                      select
                                                                      this
                                                                      required
                                                                      field.
                                                                    </div>
                                                                  )}
                                                                </Grid>
                                                              </Grid>
                                                            </>
                                                          );
                                                        }
                                                      )}

                                                    <Grid className="buttonConttainer">
                                                      <Buttons
                                                        aria-describedby="dd"
                                                        variant="variantOutlineBorder"
                                                        type="button"
                                                        name="btn"
                                                        label={"Add Item"}
                                                        id="btnAddEquipment"
                                                        variantOutlineBorder={
                                                          true
                                                        }
                                                        onClick={(e) =>
                                                          handleRadioType(
                                                            null,
                                                            question,
                                                            subcategory,
                                                            category,
                                                            "appendFrom"
                                                          )
                                                        }
                                                      ></Buttons>
                                                      {question?.responseType ===
                                                        3 && (
                                                        <ThreeQuestion
                                                          formchangeThreeHnadle={
                                                            formchangeThreeHnadle
                                                          }
                                                          question={question}
                                                          subcategory={
                                                            subcategory
                                                          }
                                                          category={category}
                                                        />
                                                      )}
                                                    </Grid>
                                                  </>
                                                ) : null}
                                              </div>
                                            </>
                                          )}
                                        </li>
                                        {/* {previousItem?.length > 0 ? ( */}
                                        <>
                                          {" "}
                                          <PreviousOpenItems
                                            openItemHandler={openItemHandler}
                                            previousItem={
                                              previousItem
                                                ? previousItem?.filter(
                                                    (x) =>
                                                      x.questionId ===
                                                        question.key &&
                                                      x.inspectionId !==
                                                        reportId
                                                  )
                                                : []
                                            }
                                            getSwReference={getSwReference}
                                          />{" "}
                                        </>
                                        {/* ) : (
                                      getOpenItem(question)
                                    )} */}
                                      </>
                                    )
                                  )}
                                </ul>
                              </AccordionDetails>
                            </Accordion>
                          )
                        )}
                      </AccordionDetails>
                    </Accordion>
                    <div>
                      {category.catError ? (
                        <p className="error">All Question are required.</p>
                      ) : null}
                    </div>
                  </>
                ))}
              </div>
            </Grid>
          )}
          {isNorthCarolina && (
            <Grid container>
              <NorthCarolina
                userInfo={userInfo}
                swRainFallData={swRainFallData}
                getSwReference={getSwReference}
                swInspectionConfQuestionAns={swInspectionConfQuestionAns}
                setSwInspectionConfQuestionAns={setSwInspectionConfQuestionAns}
                swInspectionRainfallData={swInspectionRainfallData}
                swInspectionCurrentProjectPhase={
                  swInspectionCurrentProjectPhase
                }
                setSwInspectionCurrentProjectPhase={
                  setSwInspectionCurrentProjectPhase
                }
                swInspectionGroundStabilization={
                  swInspectionGroundStabilization
                }
                setSwInspectionGroundStabilization={
                  setSwInspectionGroundStabilization
                }
                swInspectionObservation={swInspectionObservation}
                setSwInspectionObservation={setSwInspectionObservation}
                swInspectionLotDisturbance={swInspectionLotDisturbance}
                setSwInspectionLotDisturbance={setSwInspectionLotDisturbance}
              />
            </Grid>
          )}
          <Grid container>
            <Grid item md={12} xs={12} my={5}>
              <p className="defaultMsg">
                "I certify under penalty of law that this report and all
                attachments were prepared under my direction or supervision in
                accordance with a system designed to assure that certified
                personnel properly gather and evaluate the information
                submitted. Based on my inquiry of the person or persons who
                manage the system, or those persons directly responsible for
                gathering the information, the information submitted is, to the
                best of my knowledge and belief, true, accurate, and complete. I
                am aware that there are significant penalties for submitting
                false information, including the possibility of fine and
                imprisonment for knowing violations."
              </p>
            </Grid>

            {!isSignatureProfile && (
              <Grid item md={12} xs={12}>
                <FormLabels label="Add Inspector’s Signature" />

                <input
                  type="file"
                  ref={hiddenSignatureInput}
                  onChange={(event) => {
                    contractorsignatureUpload(event);
                  }}
                  style={{ display: "none" }}
                  accept="image/*"
                />
                <label
                  className="signatureBtn"
                  onClick={() => {
                    hiddenSignatureInput.current.click();
                    setImageCheck(true);
                  }}
                  htmlFor="actual-btn"
                >
                  <AddIcon /> Add Signature
                </label>
              </Grid>
            )}

            {signaureFileError && (
              <div className="error">{signaureFileErrorMessage}</div>
            )}

            <Grid item lg={12} xs={12} mt={1}>
              {!selectedContractorSignature && signatureError && (
                <p className="error">Please Add Signature</p>
              )}
            </Grid>

            <Grid item container mt={0} className="topHeaderProfile">
              <Grid item lg={6} xs={12} mt={2}>
                <Grid className="signatureImg">
                  {isSignatureProfile ? (
                    <img
                      className="borderSignatureImg"
                      src={isSignatureProfile}
                      alt=""
                    />
                  ) : (
                    <>
                      {selectedContractorSignature && (
                        <img
                          className="borderSignatureImg"
                          src={selectedContractorSignature}
                          alt=""
                        />
                      )}
                    </>
                  )}
                  {isSignatureProfile && (
                    <div className="overideLables">
                      <div className="formLabel">Authorized signature</div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={7} className="buttonEnd">
            <Grid item lg={4} xs={12} sm={6} md={6}>
              <Buttons
                varientText={true}
                label="Save As Draft"
                onClick={saveData}
              />
            </Grid>
            <Grid item lg={4} xs={12} sm={6} md={6}>
              <Buttons
                varientContained={true}
                label={"Save"}
                onClick={submitData}
                // disabled={isSubmit ? true : false}
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default AddReport;
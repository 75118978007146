import * as contractorAction from "../actions/contractorAction";
import { contractorType } from "../constants/contractorType";
import {
  getContractorList,
  createContractor,
  deleteContractor,
  getContractorReport,
  updateContractor,
  getPersonalContractorList,
  createPersonalContractor,
  deletePersonalContractor,
  getPersonalContractorReport,
  updatePersonalContractor,
  getOnboardedContractorList,
  updateOnboardedContractorList,
  getContractorConfigurationDetails,
  updateContractorOnboardDetails,
  updateContractorOnboardStatus,
} from "../../services/contractorService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* createContractorSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(createContractor, payload);
    if (!data && status !== 200) throw apiError(error);
    const casePage = contractorType.CREATE_PAGE;
    yield put(
      contractorAction.createContractorSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Case Failed" };
    yield put(contractorAction.createContractorFailure(data));
  }
}

export function* getContractorListSaga(params) {
  try {
    const { data, status, error } = yield call(getContractorList, params);
    if (!data && status !== 200) throw apiError(error);
    const ContractorPage = contractorType.CASE_PAGE;
    if (params.data.isSendMail) {
      yield put(contractorAction.getContractorListEmailSuccess());
    } else {
      yield put(
        contractorAction.getContractorListSuccess({
          ContractorPage,
          data,
        })
      );
    }
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Contractor List Failed" };
    yield put(contractorAction.getContractorListFailure(data));
  }
}

export function* contractorReportSaga({ contractorId }) {
  console.debug("ContractorReport");
  try {
    const { data, status, error } = yield call(
      getContractorReport,
      contractorId
    );
    if (!data && status !== 200) throw apiError(error);
    const contractorReportPage = contractorType.contractorReportPage;
    yield put(
      contractorAction.getContractorDetailsSuccess({
        contractorReportPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Contractor Report Failed" };
    yield put(contractorAction.getContractorDetailsFailure(data));
  }
}

export function* updateContractorSaga(params) {
  console.debug("updateContractorSaga");
  try {
    const { data, status, error } = yield call(updateContractor, params);
    if (!data && status !== 200) throw apiError(error);
    const updatePage = contractorType.UPDATE_PAGE;
    yield put(
      contractorAction.updateContractorSuccess({
        updatePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Contractor updation Failed" };
    yield put(contractorAction.updateContractorFailure(data));
  }
}

function* deleteContractorSaga(payload) {
  try {
    const { data, status } = yield call(deleteContractor, payload);
    const casePage = contractorType.CASE_PAGE;

    yield put(
      contractorAction.deleteContractorSuccess({
        casePage,
        data,
        status,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Contractor List Failed" };
    yield put(contractorAction.deleteContractorFailure(data));
  }
}

export function* createPersonalContractorSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(
      createPersonalContractor,
      payload
    );
    if (!data && status !== 200) throw apiError(error);
    const casePage = contractorType.CREATE_PAGE;
    yield put(
      contractorAction.createPersonalContractorSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Case Personal Failed" };
    yield put(contractorAction.createPersonalContractorFailure(data));
  }
}

export function* getPersonalContractorListSaga(params) {
  try {
    const { data, status, error } = yield call(
      getPersonalContractorList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const ContractorPage = contractorType.CASE_PAGE;

    if (params.data.isSendMail) {
      yield put(contractorAction.getPersonalContractorListEmailSuccess());
    } else {
      yield put(
        contractorAction.getPersonalContractorListSuccess({
          ContractorPage,
          data,
        })
      );
    }
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Contractor Personal List Failed" };
    yield put(contractorAction.getPersonalContractorListFailure(data));
  }
}

export function* personalContractorReportSaga({ contractorId }) {
  try {
    const { data, status, error } = yield call(
      getPersonalContractorReport,
      contractorId
    );
    if (!data && status !== 200) throw apiError(error);
    const contractorReportPage = contractorType.contractorReportPage;
    yield put(
      contractorAction.getPersonalContractorDetailsSuccess({
        contractorReportPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Contractor Personal Report Failed" };
    yield put(contractorAction.getPersonalContractorDetailsFailure(data));
  }
}

export function* updatePersonalContractorSaga(params) {
  try {
    const { data, status, error } = yield call(
      updatePersonalContractor,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const updatePage = contractorType.UPDATE_PAGE;
    yield put(
      contractorAction.updatePersonalContractorSuccess({
        updatePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Contractor Personal updation Failed" };
    yield put(contractorAction.updatePersonalContractorFailure(data));
  }
}

function* deletePersonalContractorSaga(payload) {
  try {
    const { data, status } = yield call(deletePersonalContractor, payload);
    const casePage = contractorType.CASE_PAGE;

    yield put(
      contractorAction.deletePersonalContractorSuccess({
        casePage,
        data,
        status,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Contractor Personal List Failed" };
    yield put(contractorAction.deletePersonalContractorFailure(data));
  }
}

export function* getOnboardedContractorListSaga(params) {
  console.debug("contractorOnboardedListSaga");
  try {
    const { data, status, error } = yield call(
      getOnboardedContractorList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const ContractorPage = contractorType.CASE_PAGE;
    if (params?.data?.isSendMail) {
      yield put(contractorAction.getOnboardedContractorEmailSuccess());
    } else {
      yield put(
        contractorAction.getOnboardedContractorSuccess({
          ContractorPage,
          data,
        })
      );
    }
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Onboarded Contractor List Failed" };
    yield put(contractorAction.getOnboardedContractorFailure(data));
  }
}

export function* updateOnboardedContractorListSaga(params) {
  try {
    const { data, status, error } = yield call(
      updateOnboardedContractorList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const updatePage = contractorType.UPDATE_PAGE;
    yield put(
      contractorAction.updateOnboardedContractorListSuccess({
        updatePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || " Onboarded Contractor updation Failed" };
    yield put(contractorAction.updateOnboardedContractorListFailure(data));
  }
}

export function* contractorConfigurationDetailsSaga(paramsData) {
  console.debug("contractorConfigurationtDetailsSaga");
  try {
    const { data, status, error } = yield call(
      getContractorConfigurationDetails,
      paramsData
    );
    if (!data && status !== 200) throw apiError(error);
    const ContractorPage = contractorType.CASE_PAGE;
    yield put(
      contractorAction.getContractorConfigurationDetsilsSuccess({
        ContractorPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Contractor Configuration Details Failed" };
    yield put(contractorAction.getContractorConfigurationDetsilsFailure(data));
  }
}

export function* updateContractorOnboardSaga(params) {
  console.debug("updateContractorOnboardSaga");
  try {
    const { data, status, error } = yield call(
      updateContractorOnboardDetails,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const ContractorPage = contractorType.USER_PAGE;
    yield put(
      contractorAction.updateContractorOnboardSuccess({
        ContractorPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Contractor Onboard updation Failed" };
    yield put(contractorAction.updateContractorOnboardFailure(data));
  }
}

export function* updateContractorOnboardStatusSaga(params) {
  console.debug("updateContractorOnboardStatusSaga");
  try {
    const { data, status, error } = yield call(
      updateContractorOnboardStatus,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const ContractorPage = contractorType.USER_PAGE;
    yield put(
      contractorAction.updateContractorOnboardStatusSuccess({
        ContractorPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Contractor Onboard updation Failed" };
    yield put(contractorAction.updateContractorOnboardStatusFailure(data));
  }
}

const myCaseSaga = [
  takeLatest(contractorType.GET_CONTRACTOR_LIST_PENDING, getContractorListSaga),
  takeLatest(contractorType.POST_CONTRACTOR_PENDING, createContractorSaga),
  takeLatest(contractorType.UPDATE_CONTRACTOR_PENDING, updateContractorSaga),
  takeLatest(contractorType.DELETE_CONTRACTOR_PENDING, deleteContractorSaga),
  takeLatest(
    contractorType.GET_CONTRACTOR_DETAILS_PENDING,
    contractorReportSaga
  ),
  takeLatest(
    contractorType.GET_PERSONAL_CONTRACTOR_LIST_PENDING,
    getPersonalContractorListSaga
  ),
  takeLatest(
    contractorType.POST_PERSONAL_CONTRACTOR_PENDING,
    createPersonalContractorSaga
  ),
  takeLatest(
    contractorType.UPDATE_PERSONAL_CONTRACTOR_PENDING,
    updatePersonalContractorSaga
  ),
  takeLatest(
    contractorType.DELETE_PERSONAL_CONTRACTOR_PENDING,
    deletePersonalContractorSaga
  ),
  takeLatest(
    contractorType.GET_PERSONAL_CONTRACTOR_DETAILS_PENDING,
    personalContractorReportSaga
  ),
  takeLatest(
    contractorType.GET_ONBOARDED_CONTRACTOR_LIST_PENDING,
    getOnboardedContractorListSaga
  ),

  takeLatest(
    contractorType.UPDATE_ONBOARDED_CONTRACTOR_LIST,
    updateOnboardedContractorListSaga
  ),

  takeLatest(
    contractorType.GET_CONTRACTOR_CONFIGURATION_DETAILS,
    contractorConfigurationDetailsSaga
  ),
  takeLatest(
    contractorType.UPDATE_CONTRACTOR_ONBOARD,
    updateContractorOnboardSaga
  ),
  takeLatest(
    contractorType.UPDATE_CONTRACTOR_ONBOARD_STATUS,
    updateContractorOnboardStatusSaga
  ),
];

export default myCaseSaga;

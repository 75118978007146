import React, { useState, useEffect } from "react";
import * as assetsAction from "../../../redux/actions/assetsAction";
import { useDispatch, useSelector } from "react-redux";
import {
  API_STATUS,
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
} from "../../../constant/constant";
import { ACCESS_DENIED, ADMIN_ADD_ASSETS } from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import AssetList from "../component/assets/assetList";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { toast, ToastContainer } from "react-toastify";
export default function AssetsContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const assetList = useSelector((state) => state.assetList);
  const assetListDelete = useSelector((state) => state.deleteAsset.status);
  const [sendEmail, setSendEmail] = useState(false);
  const showLoader = useSelector(
    (state) => state.assetList?.isLoading || state.deleteAsset.isLoading
  );

  useEffect(() => {
    if (assetListDelete === "SUCCESS") getAssetList(defaultData);
  }, [assetListDelete, dispatch]);

  const clientDivision = useSelector((state) => state.getDropdownDataKeyValue);

  const assetCategory = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.assetTypes
  );
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const divisions = userInfo?.divisions?.filter(i=>i?.serviceType == PRODUCT_ID());
  const isClient = userInfo?.isClient;

  const [clientDivisions, setClientDivisions] = useState();
  const valueCheck = clientDivisions || divisions;
  const clientSwitch = isClient || ORG_ID() !== LOGIN_ORG_ID();

  useEffect(() => {
    if (clientSwitch) {
      if (clientDivision?.status === API_STATUS.SUCCESS) {
        const divisions = clientDivision?.data?.divisons && [
          ...clientDivision?.data?.divisons,
        ];
        divisions?.unshift({ key: 1, value: "All Divisions" });
        setClientDivisions(divisions);
      }
    }
  }, [clientDivision, clientSwitch]);

  const defaultData = {
    page: 1,
    divisionId: clientSwitch && valueCheck ? 0 : divisions[0]?.key,
    pageSize: PAGE_SIZE,
    statusIds: "",
    isSendMail: false,
    search: "",
    userId: 0,
    organisationId: ORG_ID(),
  };

  useEffect(() => {
    getAssetList(defaultData);
    dispatch(assetsAction.clearAsset());
  }, []);

  const getAssetList = (data) => {
    dispatch(assetsAction.getAssetList(data));
  };

  const addAsset = () => {
    navigate(ADMIN_ADD_ASSETS);
  };
  function getAssetListData(data) {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(assetsAction.getAssetList(data));
  }

  useEffect(() => {
    const getDropdownDataKeyValueData = {
      orgId: ORG_ID(),
      flags: [6, 13],
      productId: PRODUCT_ID(),
    };
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  useEffect(() => {
    if (assetList?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("Assets has been sent to registered email ID");
    }
  }, [assetList]);

  return (
    <>
      {checkPrivileges([12, 37, 38, 39]) ? (
        <>
          <ToastContainer />
          <AssetList
            assetList={showLoader ? [] : assetList?.data}
            getContactListData={getAssetList?.data?.users}
            isAssetLoading={showLoader}
            addContact={addAsset}
            getAssetListData={getAssetListData}
            divisionDropdown={
              clientSwitch && valueCheck ? clientDivisions : divisions
            }
            assetCategory={assetCategory ?? []}
            isShowDowdownloadButton={true}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}
